/* テイクアウトページ
------------------------------------------------ */
.menu {

  &.-takeout {
    background-color: #F4F4EE;
    color: $color-bk;

    .menu__title {

      .hide {
        background-color: #F4F4EE;
      }

      .title-before {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -36%);
        display: block;
        max-width: 108px;
        width: 100%;
        z-index: 1;

        @include media(pc-l) {
          max-width: res(108px);
        }

        @include media(sp) {
          max-width: 77px;
        }

        &::before {
          content: '';
          display: block;
          padding-top: percentage(90px / 108px);
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .menu-inner {
      padding-bottom: 0;
    }
  }

  .takeout-row {
    margin-bottom: 80px;

    @include media(pc-l) {
      margin-bottom: 60px;
    }

    @include media(tb) {
      margin-bottom: 40px;
    }

    &.-mbnone {
      margin-bottom: 0;
    }
  }

  // 取扱商品
  .products {
    background-color: $color-bk;
    padding: 136px 0 140px;
    color: $color-wh;

    @include media(pc-l) {
      padding: res(136px) 0 res(140px);
    }

    @include media(sp) {
      padding: 68px 0 70px;
    }

    .menu__title .hide {
      background-color: $color-bk;
    }

    &__btn {
      max-width: 960px;
      width: 100%;
      margin: 0 auto;

      @include media(sp) {
        max-width: 400px;

        img {
          max-width: none;
          width: 100%;
        }
      }

      a:hover {
        opacity: .7;
      }
    }
  }
}

.takeout-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 260px);
  background-color: #A91118;
  z-index: 1;

  @include media(pc-l) {
    width: calc(100% - 230px);
  }

  @include media(pc-s) {
    width: 100%;
  }

  &>a {
    display: block;
    padding: 32px 0;

    @include media(pc-l) {
      padding: res(32px) 0;
    }

    @include media(sp) {
      padding: 17px 0;
    }

    &:hover {
      background-color: #9B121C;

      @include media(tb) {
        background-color: #A91118;
      }
    }
  }

  &__container {
    position: relative;
    display: block;
    max-width: 705px;
    width: 100%;
    margin: 0 auto;
    z-index: 1;

    @include media(pc-l) {
      max-width: res(705px);
    }

    @include media(sp) {
      max-width: 329px;
    }

    &::before {
      content: '';
      display: block;
      padding-top: percentage(58px / 705px);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}


.footer {

  &.-takeout {
    padding-bottom: 140px;

    @include media(pc-l) {
      padding-bottom: 120px;
    }

    @include media(sp) {
      padding-bottom: 88px;
    }
  }
}
