/* 下層ページ共通
------------------------------------------------ */
.lower {

  &--ivo {
    background-color: $color-ivo;
  }
  &--bk {
    background-color: $color-bk;
  }

  &__content {
    max-width: 1160px;
    margin: 0 auto;
    @include media(pc-l) {
      padding: 0 60px;
    }
    @include media(tb) {
      padding: 0 50px;
    }
    @include media(sp) {
      padding: 0 20px;
    }

    &--news {
      max-width: 1080px;
    }

    &--single {
      max-width: 960px;
    }

    &--menu {
      @include media(pc-l) {
        padding: 0 50px;
      }
      @include media(tb) {
        padding: 0 30px;
      }
      @include media(sp) {
        padding: 0 20px;
      }
    }
  }
}