/* 味味香とは／会社概要
------------------------------------------------ */
.about {

  .about-inner {
    padding: 256px 0 240px;
    @include media(pc-l) {
      padding: 130px 0 215px;
    }
    @include media(pc-s) {
      padding-top: 180px;
    }
    @include media(tb) {
      padding: 140px 0 170px;
    }
    @include media(sp) {
      padding: 90px 0 120px;
    }
  }

  .about-container {
    margin-bottom: 144px;
    padding-bottom: 137px;
    border-bottom: 1px solid $color-gold02;
    @include media(pc-l) {
      margin-bottom: 128px;
      padding-bottom: 120px;
    }
    @include media(tb) {
      margin-bottom: 100px;
      padding-bottom: 80px;
    }
    @include media(sp) {
      margin-bottom: 70px;
      padding-bottom: 60px;
    }
  }

  &__title {
    margin-bottom: 100px;
    font-size: 6rem;
    color: $color-gold02;
    @include media(pc-l) {
      margin-bottom: 60px;
      font-size: 4.8rem;
    }
    @include media(tb) {
      font-size: 4.4rem;
    }
    @include media(sp) {
      margin-bottom: 40px;
      font-size: 3rem;
    }
    @include media(se) {
      font-size: 2.8rem;
    }
  }

  &__image {
    margin-bottom: 120px;
    @include media(pc-l) {
      margin-bottom: 110px;
    }
    @include media(tb) {
      margin-bottom: 70px;
    }
    @include media(sp) {
      margin-bottom: 50px;
    }
  }

  &__text {
    margin-bottom: 70px;
    font-size: 1.8rem;
    line-height: 2;
    @include media(pc-l) {
      margin-bottom: 62px;
      font-size: 1.6rem;
    }
    @include media(tb) {
      margin-bottom: 54px;
      font-size: 1.4rem;
    }
    @include media(sp) {
      margin-bottom: 40px;
      font-size: 1.3rem;
    }
    @include media(se) {
      font-size: 1.2rem;
    }
  }

  .about-shop {

    &__name {
      margin-bottom: 14px;
      font-size: 2.4rem;
      @include media(pc-l) {
        margin-bottom: 12px;
        font-size: 2.2rem;
      }
      @include media(tb) {
        font-size: 1.8rem;
      }
      @include media(sp) {
        margin-bottom: 10px;
        font-size: 1.6rem;
      }
    }

    &__address {
      font-size: 1.6rem;
      @include media(pc-l) {
        font-size: 1.4rem;
      }
      @include media(sp) {
        font-size: 1.2rem;
      }
    }
  }

  .company {
    text-align: center;

    .company-manage {
      position: relative;
      margin-bottom: 55px;
      padding-bottom: 63px;
      @include media(pc-l) {
        margin-bottom: 54px;
        padding-bottom: 56px;
      }
      @include media(tb) {
        margin-bottom: 46px;
        padding-bottom: 50px;
      }
      @include media(sp) {
        margin-bottom: 40px;
        padding-bottom: 44px;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 24px;
        height: 4px;
        background-color: $color-bk;
        @include media(sp) {
          width: 20px;
          height: 3px;
        }
      }

      &__text {
        margin-bottom: 55px;
        font-size: 2.4rem;
        @include media(pc-l) {
          margin-bottom: 50px;
          font-size: 2.2rem;
        }
        @include media(tb) {
          margin-bottom: 40px;
          font-size: 1.8rem;
        }
        @include media(sp) {
          margin-bottom: 24px;
          font-size: 1.4rem;
        }
      }

      &__logo {
        max-width: 606px;
        margin: 0 auto 24px;
        @include media(pc-l) {
          max-width: 540px;
          margin-bottom: 22px;
        }
        @include media(tb) {
          max-width: 480px;
          margin-bottom: 18px;
        }
        @include media(sp) {
          max-width: 300px;
          margin-bottom: 14px;
        }
      }

      &__address {
        margin-bottom: 28px;
        font-size: 1.8rem;
        @include media(pc-l) {
          margin-bottom: 24px;
          font-size: 1.6rem;
        }
        @include media(tb) {
          margin-bottom: 20px;
          font-size: 1.5rem;
        }
        @include media(sp) {
          margin-bottom: 16px;
          font-size: 1.2rem;
        }
      }

      &__tel {
        font-size: 2.6rem;
        @include media(pc-l) {
          font-size: 2.3rem;
        }
        @include media(tb) {
          font-size: 2rem;
        }
        @include media(sp) {
          font-size: 1.7rem;
        }
      }
    }

    .company-des {

      .company-row {
        margin-bottom: 70px;
        @include media(pc-l) {
          margin-bottom: 60px;
        }
        @include media(sp) {
          margin-bottom: 34px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__title {
        margin-bottom: 32px;
        font-size: 2.2rem;
        @include media(pc-l) {
          margin-bottom: 26px;
          font-size: 2rem;
        }
        @include media(tb) {
          margin-bottom: 20px;
          font-size: 1.8rem;
        }
        @include media(sp) {
          margin-bottom: 16px;
          font-size: 1.5rem;
        }
        @include media(se) {
          font-size: 1.4rem;
        }
      }

      &__text {
        font-size: 1.8rem;
        line-height: 2;
        @include media(pc-l) {
          font-size: 1.6rem;
        }
        @include media(tb) {
          font-size: 1.4rem;
        }
        @include media(sp) {
          font-size: 1.3rem;
        }
        @include media(se) {
          font-size: 1.2rem;
        }
      }
    }
  }
}