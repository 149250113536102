/* CSS Document */
* {
  margin: 0;
  padding: 0;
  line-height: 1;
}

*,
::after,
::before {
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
  font-size: $font-size;
  // font-feature-settings: "palt";
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font;
  color: $color-bk;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
p,
dl,
dt,
dd,
img {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: normal;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.4s;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 1.6rem;
}

button {
  background-color: transparent;
  border: none;
  font-family: inherit;
  outline: none;
  padding: 0;
  appearance: none;
  transition: 0.4s;
  cursor: pointer;
}

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &:focus {
      outline-offset: -2px;
    }
}

address {
  font-style: normal;
}

.scroll-prevent {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  overflow: visible;
}

// breakpoint
$breakpoints: (
  pc-l: "screen and (max-width: 1500px)",
  pc-s: "screen and (max-width: 1200px)",
  tb: "screen and (max-width: 960px)",
  sp: "screen and (max-width: 660px)",
  se: "screen and (max-width: 374px)",
);
@mixin media($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}