/* ニュース
------------------------------------------------ */
.news {

  .news-head {
    background-color: $color-bk;
    padding: 74px 0 66px;
    @include media(pc-l) {
      padding: 66px 0 60px;
    }
    @include media(tb) {
      padding: 58px 0 52px;
    }
    @include media(sp) {
      padding: 40px 0 38px;
    }

    &__title {
      font-size: 4.8rem;
      color: $color-wh;
      text-align: center;
      @include media(pc-l) {
        font-size: 4.2rem;
      }
      @include media(tb) {
        font-size: 3.8rem;
      }
      @include media(sp) {
        font-size: 2.8rem;
      }

      .en {
        position: relative;
        margin-top: 20px;
        padding-top: 28px;
        font-size: 1.8rem;
        font-family: $font-en;
        letter-spacing: 0.2em;
        @include media(pc-l) {
          font-size: 1.6rem;
        }
        @include media(tb) {
          margin-top: 16px;
          padding-top: 22px;
          font-size: 1.4rem;
        }
        @include media(sp) {
          margin-top: 18px;
          padding-top: 24px;
          font-size: 1.6rem;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 40px;
          height: 4px;
          background-image: url(../images/common/icon/line01.svg);
          background-repeat: no-repeat;
          background-size: cover;
          @include media(tb) {
            width: 32px;
            height: 3px;
          }
        }
      }

      a {
        display: inline-block;

        &:hover {
          opacity: .6;
        }
      }
    }
  }

  .news-inner {
    padding: 130px 0 210px;
    @include media(pc-l) {
      padding: 114px 0 184px;
    }
    @include media(tb) {
      padding: 100px 0 160px;
    }
    @include media(sp) {
      padding: 70px 0 100px;
    }
  }

  .news__selectBtn {
    display: block;
    width: 260px;
    margin: 0 auto 128px;
    padding: 0 15px;
    border: 1px solid $color-bk;
    border-radius: 0.2em;
    font-size: 1.8rem;
    font-family: $font-g;
    @include media(pc-l) {
      width: 230px;
      margin-bottom: 110px;
      font-size: 1.6rem;
    }
    @include media(tb) {
      width: 200px;
      margin-bottom: 100px;
      font-size: 1.4rem;
    }
    @include media(sp) {
      margin-bottom: 60px;
    }

    &--none {
      margin-bottom: 0 !important;
    }

    &:hover {
      box-shadow: 0 0 5px #666 inset;
    }

    &:not([size]):not([multiple]) {
      height: 36px;
      @include media(pc-l) {
        height: 34px;
      }
      @include media(tb) {
        height: 32px;
      }
      @include media(sp) {
        height: 30px;
      }
    }
  }

  .news-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -60px;
    margin-top: -90px;
    margin-bottom: 120px;
    @include media(pc-l) {
      margin-left: -50px;
      margin-top: -80px;
      margin-bottom: 100px;
    }
    @include media(tb) {
      margin-left: -46px;
      margin-top: -70px;
      margin-bottom: 90px;
    }
    @include media(sp) {
      margin-left: 0;
      margin-top: -60px;
      margin-bottom: 80px;
    }

    &__item {
      position: relative;
      width: calc(33.333% - 60px);
      margin-top: 90px;
      margin-left: 60px;
      transition: opacity .3s;
      @include media(pc-l) {
        width: calc(33.333% - 50px);
        margin-top: 80px;
        margin-left: 50px;
      }
      @include media(tb) {
        width: calc(50% - 46px);
        margin-top: 70px;
        margin-left: 46px;
      }
      @include media(sp) {
        width: 100%;
        margin-top: 60px;
        margin-left: 0;
      }

      &:hover {
        opacity: .7;
      }

      a {
        @include linkall;
      }
    }
  }

  &__image {
    width: 100%;
    height: 240px;
    margin-bottom: 25px;
    @include media(pc-l) {
      height: 215px;
      margin-bottom: 22px;
    }
    @include media(tb) {
      height: 240px;
      margin-bottom: 16px;
    }
    @include media(sp) {
      height: 250px;
    }

    img {
      max-width: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }

  &__time {
    display: block;
    margin-bottom: 6px;
    font-size: 1.8rem;
    font-family: $font-en;
    color: $color-red;
    @include media(pc-l) {
      margin-bottom: 4px;
      font-size: 1.6rem;
    }
    @include media(tb) {
      margin-bottom: 6px;
      font-size: 1.4rem;
    }
    @include media(sp) {
      font-size: 1.3rem;
    }
  }

  &__title {
    font-size: 1.8rem;
    line-height: 1.8;
    @include media(pc-l) {
      font-size: 1.6rem;
    }
    @include media(tb) {
      font-size: 1.4rem;
    }
    @include media(sp) {
      font-size: 1.3rem;
    }
  }
}