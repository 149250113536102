/* footer
------------------------------------------------ */
.footer {
  position: relative;
  background-image: url(../images/common/footer/footer_bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-left: 260px;
  padding: 96px 0 78px;
  color: $color-wh;

  @include media(pc-l) {
    margin-left: 230px;
    padding: 85px 0 74px;
  }

  @include media(pc-s) {
    margin-left: 0;
  }

  @include media(tb) {
    background-position: top center;
    padding: 0 0 85px;
  }

  @include media(sp) {
    padding-bottom: 52px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
  }

  .wrapper {
    position: relative;

    @include media(sp) {
      padding: 0 30px;
    }

    @include media(se) {
      padding: 0 20px;
    }
  }

  .fnav {
    position: relative;
    display: flex;
    justify-content: center;

    @include media(tb) {
      display: block;
    }

    &__list {
      display: flex;

      @include media(tb) {
        @include re-m;
        display: block;
      }
    }

    &__item {
      padding: 0 20px;
      border-right: 1px solid $color-wh;

      @include media(tb) {
        padding: 0;
        border-right: none;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border-right: none;
      }

      a {
        display: block;
        font-size: 1.5rem;

        @include media(pc-l) {
          font-size: 1.4rem;
        }

        @include media(tb) {
          padding: 30px 50px;
          border-bottom: 1px solid $color-wh;
          font-size: 2rem;
        }

        @include media(sp) {
          padding: 20px 32px;
          font-size: 1.6rem;
        }

        @include media(se) {
          padding: 20px;
        }

        &:hover {
          color: $color-gold01;
        }
      }
    }
  }

  .footer-name {
    display: flex;
    align-items: center;
    margin: 70px 0 25px;
    padding-top: 45px;
    border-top: 1px solid $color-wh;

    @include media(pc-l) {
      margin: 65px 0 22px;
      padding-top: 40px;
    }

    @include media(tb) {
      display: block;
      margin-bottom: 40px;
      padding-top: 0;
      border-top: none;
    }

    @include media(sp) {
      margin: 25px 0 32px;
    }

    &__image {
      width: 278px;
      margin-right: 27px;

      @include media(pc-l) {
        width: 246px;
        margin-right: 24px;
      }

      @include media(tb) {
        width: 320px;
        margin-right: 0;
        margin-bottom: 26px;
      }

      @include media(sp) {
        width: 200px;
        margin-bottom: 18px;
      }

      a {
        display: block;

        &:hover {
          opacity: .7;
        }
      }
    }

    &__text {
      font-size: 2.4rem;

      @include media(pc-l) {
        font-size: 2.2rem;
      }

      @include media(sp) {
        font-size: 1.5rem;
      }

      @include media(se) {
        font-size: 1.4rem;
      }
    }
  }

  &__time {
    margin-bottom: 12px;
    font-size: 2rem;

    @include media(pc-l) {
      margin-bottom: 10px;
      font-size: 1.8rem;
    }

    @include media(tb) {
      font-size: 2rem;
      line-height: 1.8;
      font-feature-settings: "palt";
    }

    @include media(sp) {
      margin-bottom: 4px;
      font-size: 1.6rem;
    }
  }

  &__note,
  &__address {
    font-size: 1.6rem;

    @include media(pc-l) {
      font-size: 1.4rem;
    }

    @include media(tb) {
      font-size: 1.6rem;
    }

    @include media(sp) {
      font-size: 1.4rem;
    }
  }

  &__note {
    margin-bottom: 25px;
    font-feature-settings: "palt";

    @include media(pc-l) {
      margin-bottom: 20px;
    }

    @include media(tb) {
      margin-bottom: 34px;
      line-height: 1.6;
    }

    @include media(sp) {
      margin-bottom: 30px;
    }
  }

  &__address {
    margin-bottom: 20px;

    @include media(pc-l) {
      margin-bottom: 18px;
    }

    @include media(tb) {
      margin-bottom: 16px;
      line-height: 1.8;
    }

    @include media(sp) {
      margin-bottom: 12px;
    }
  }

  .footer-contact {
    display: flex;
    margin-bottom: 44px;

    @include media(pc-l) {
      margin-bottom: 40px;
    }

    @include media(tb) {
      display: block;
      margin-bottom: 32px;
    }

    @include media(sp) {
      margin-bottom: 26px;
    }

    &__tel {
      display: block;
      font-size: 2.2rem;

      @include media(pc-l) {
        font-size: 2rem;
      }

      @include media(tb) {
        margin-bottom: 30px;
        font-size: 3rem;
      }

      @include media(sp) {
        margin-bottom: 24px;
        font-size: 2rem;
      }

      @include media(se) {
        font-size: 1.8rem;
      }

      .big {
        font-size: 3.2rem;
        letter-spacing: 0.1em;

        @include media(pc-l) {
          font-size: 2.8rem;
        }

        @include media(tb) {
          margin-left: 5px;
          font-size: 4rem;
        }

        @include media(sp) {
          margin-left: 3px;
          font-size: 3rem;
        }

        @include media(se) {
          font-size: 2.8rem;
        }
      }
    }

    &__list {
      display: flex;
      margin-left: auto;
    }

    &__item {
      width: 40px;
      margin-left: 20px;

      @include media(pc-l) {
        width: 36px;
        margin-left: 18px;
      }

      @include media(tb) {
        width: 44px;
        margin-left: 24px;
      }

      @include media(sp) {
        width: 36px;
        margin-left: 18px;
      }

      @include media(se) {
        width: 34px;
      }

      &:first-child {
        margin-left: 0;
      }

      a {
        display: block;

        &:hover {
          opacity: .6;

          @include media(tb) {
            opacity: 1;
          }
        }
      }
    }
  }

  .copyright {
    font-size: 1.2rem;
    font-family: $font-g;
    text-align: right;

    @include media(tb) {
      font-size: 1.4rem;
      text-align: left;
    }

    @include media(sp) {
      font-size: 1.1rem;
    }

    @include media(se) {
      font-size: 1.1rem;
    }
  }
}
