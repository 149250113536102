/* main
------------------------------------------------ */
.main {
  display: block;
  margin-left: 260px;
  @include media(pc-l) {
    margin-left: 230px;
  }
  @include media(pc-s) {
    margin-top: 140px;
    margin-left: 0;
  }
  @include media(tb) {
    margin-top: 110px;
  }
  @include media(sp) {
    margin-top: 70px;
  }
}


/* スマホ横
------------------------------------------------ */
@media only screen and (orientation:landscape) and (max-width:813px){
  .main {
    margin-top: 70px;
  }
}