/* スライドショー
-------------------------------------------- */
.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  @include media(pc-s) {
    height: calc(100vh - 140px);
  }
  @include media(tb) {
    height: calc(100vh - 110px);
  }
  @include media(sp) {
    height: calc(100vh - 70px);
  }

  // スライダー関係
  .swiper-container {
      width: 100%;
      height: 100%;

      .swiper-slide-active .slide-img,
      .swiper-slide-duplicate-active .slide-img,
      .swiper-slide-prev .slide-img{
          animation: zoomUp 10s linear 0s 1 normal both;  
      }
  
      .slide-img img{
        display: block;
        max-width: none;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        @include media(pc-s) {
          height: calc(100vh - 140px);
        }
        @include media(tb) {
          height: calc(100vh - 110px);
        }
        @include media(sp) {
          height: calc(100vh - 70px);
        }
      }
  }

  &__text {
    position: absolute;
    top: 8%;
    left: 7%;
    width: 370px;
    z-index: 1;
    @include media(pc-l) {
      width: 335px;
    }
    @include media(tb) {
      top: 6%;
      width: 300px;
    }
    @include media(sp) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 280px;
    }

    img {
      filter: drop-shadow(0 0 3px rgba(0, 0, 0, 1));
    }
  }
}


/* スマホ横
------------------------------------------------ */
@media only screen and (orientation:landscape) and (max-width:813px){
  .hero {
    height: 400px;
    margin-top: 70px;
  
    // スライダー関係
    .swiper-container {
    
      .slide-img img{
        height: 400px;
      }
    }
  
    &__text {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 280px;
    }
  }  
}