/* メニューページ
------------------------------------------------ */
.menu {
  color: $color-wh;

  // メニューヘッダー
  // -------------------------------------------
  .menu-head {
    padding: 270px 0 150px;
    text-align: center;

    @include media(pc-l) {
      padding: 236px 0 133px;
    }

    @include media(pc-s) {
      padding-top: 180px;
    }

    @include media(tb) {
      padding: 140px 0 90px;
    }

    @include media(sp) {
      padding: 100px 0 70px;
    }

    &__title {
      margin-bottom: 60px;
      font-size: 8rem;

      @include media(pc-l) {
        margin-bottom: 50px;
        font-size: 7rem;
      }

      @include media(tb) {
        margin-bottom: 40px;
        font-size: 4.6rem;
      }

      @include media(sp) {
        font-size: 3.6rem;
      }

      @include media(se) {
        font-size: 3.2rem;
      }

      .en {
        position: relative;
        margin-top: 40px;
        padding-top: 50px;
        font-size: 2.2rem;
        font-family: $font-en;
        letter-spacing: 0.2em;

        @include media(pc-l) {
          margin-top: 38px;
          padding-top: 44px;
          font-size: 2rem;
        }

        @include media(tb) {
          margin-top: 30px;
          padding-top: 36px;
          font-size: 1.8rem;
        }

        @include media(sp) {
          margin-top: 24px;
          padding-top: 26px;
          font-size: 1.7rem;
        }

        @include media(se) {
          font-size: 1.6rem;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 160px;
          height: 4px;
          background-image: url(../images/common/icon/line01.svg);
          background-repeat: repeat-x;

          @include media(tb) {
            width: 120px;
            height: 3px;
          }

          @include media(sp) {
            width: 90px;
          }
        }
      }
    }

    &__text {
      font-size: 2.2rem;
      line-height: 2.2;

      @include media(pc-l) {
        font-size: 2rem;
      }

      @include media(tb) {
        font-size: 1.8rem;
      }

      @include media(sp) {
        font-size: 1.5rem;
      }

      @include media(se) {
        font-size: 1.4rem;
      }
    }
  }


  // メニュー共通
  // -------------------------------------------
  .menu-inner {
    padding-bottom: 300px;

    @include media(pc-l) {
      padding-bottom: 270px;
    }

    @include media(tb) {
      padding-bottom: 200px;
    }

    @include media(sp) {
      padding-bottom: 140px;
    }
  }

  &__title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 360px;
    margin: 0 auto 80px;
    padding: 28px 0;
    border: 2px solid $color-red;
    font-size: 4.2rem;
    text-align: center;

    @include media(pc-l) {
      max-width: 320px;
      margin-bottom: 70px;
      padding: 24px 0;
      font-size: 3.6rem;
    }

    @include media(tb) {
      max-width: 280px;
      margin-bottom: 60px;
      padding: 22px 0;
      font-size: 2.8rem;
    }

    @include media(sp) {
      max-width: 220px;
      margin-bottom: 50px;
      padding: 18px 0;
      border-width: 1px;
      font-size: 2.4rem;
    }

    @include media(se) {
      max-width: 200px;
      padding: 16px 0;
      font-size: 2.2rem;
    }

    &--mb {
      // カレーうどん
      margin-bottom: 60px;

      @include media(pc-l) {
        margin-bottom: 46px;
      }

      @include media(tb) {
        margin-bottom: 40px;
      }

      @include media(sp) {
        margin-bottom: 30px;
      }
    }

    .hide {
      position: absolute;
      display: block;
      width: 12px;
      height: 12px;
      background-color: $color-bk;

      @include media(tb) {
        width: 10px;
        height: 10px;
      }

      &--01 {
        top: -2px;
        left: -2px;

        @include media(sp) {
          top: -1px;
          left: -1px;
        }
      }

      &--02 {
        top: -2px;
        right: -2px;

        @include media(sp) {
          top: -1px;
          right: -1px;
        }
      }

      &--03 {
        bottom: -2px;
        right: -2px;

        @include media(sp) {
          bottom: -1px;
          right: -1px;
        }
      }

      &--04 {
        bottom: -2px;
        left: -2px;

        @include media(sp) {
          bottom: -1px;
          left: -1px;
        }
      }
    }

    .small {
      font-size: 2.4rem;

      @include media(pc-l) {
        font-size: 2rem;
      }

      @include media(tb) {
        font-size: 1.6rem;
      }

      @include media(sp) {
        font-size: 1.4rem;
      }
    }
  }

  .menu-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 18px;
    margin-left: -80px;

    @include media(pc-l) {
      margin-left: -50px;
    }

    @include media(tb) {
      margin-left: -30px;
    }

    @include media(sp) {
      margin-bottom: 40px;
      margin-left: 0;
    }

    &--sub {
      margin-top: -50px;
      margin-bottom: 0 !important;

      @include media(pc-l) {
        margin-top: -44px;
      }

      @include media(tb) {
        margin-top: -40px;
      }

      @include media(sp) {
        margin-top: -30px;
      }
    }

    &.mb0 {
      margin-bottom: 0 !important;
    }

    &__item {
      width: calc(50% - 80px);
      margin-top: -100px;
      padding-top: 100px;
      margin-left: 80px;

      @include media(pc-l) {
        width: calc(50% - 50px);
        margin-top: -80px;
        padding-top: 80px;
        margin-left: 50px;
      }

      @include media(pc-s) {
        margin-top: -200px;
        padding-top: 200px;
      }

      @include media(tb) {
        width: calc(50% - 30px);
        margin-top: -150px;
        padding-top: 150px;
        margin-left: 30px;
      }

      @include media(sp) {
        width: 100%;
        margin-top: -110px;
        padding-top: 110px;
        margin-left: 0;
      }

      &--sub {
        margin-top: 50px;
        padding-top: 0 !important;

        @include media(pc-l) {
          margin-top: 44px;
        }

        @include media(pc-s) {
          margin-top: 44px;
        }

        @include media(tb) {
          margin-top: 40px;
        }

        @include media(sp) {
          margin-top: 30px;
        }
      }
    }

    &__image {

      img {
        max-width: none;
        width: 100%;

        @include media(sp) {}
      }
    }

    .menu-list-area {
      min-height: 320px;
      padding-top: 32px;

      @include media(pc-l) {
        min-height: 284px;
        padding-top: 28px;
      }

      @include media(tb) {
        min-height: 270px;
        padding-top: 24px;
      }

      @include media(sp) {
        min-height: 230px;
        padding-top: 20px;

        &--pb {
          padding-bottom: 30px;
        }
      }

      .menu-box {
        position: relative;
        margin-bottom: 22px;
        padding-bottom: 28px;

        @include media(tb) {
          margin-bottom: 16px;
          padding-bottom: 24px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 40px;
          height: 4px;
          background-image: url(../images/common/icon/line01.svg);
          background-repeat: no-repeat;
          background-size: cover;

          @include media(tb) {
            width: 32px;
            height: 3px;
          }

          @include media(sp) {
            width: 30px;
          }
        }
      }

      &__title {
        font-size: 3.2rem;

        @include media(pc-l) {
          font-size: 2.6rem;
        }

        @include media(tb) {
          font-size: 2.2rem;
        }

        @include media(se) {
          font-size: 2rem;
        }
      }

      &__price {
        margin-top: 16px;
        font-size: 2.5rem;

        @include media(pc-l) {
          margin-top: 14px;
          font-size: 2rem;
        }

        @include media(tb) {
          margin-top: 12px;
          font-size: 1.7rem;
        }

        &>span {
          font-size: 1.8rem;
          vertical-align: bottom;

          @include media(pc-l) {
            font-size: 1.6rem;
          }

          @include media(tb) {
            font-size: 1.2rem;
          }
        }
      }

      &__text {
        font-size: 1.8rem;
        line-height: 1.8;

        @include media(pc-l) {
          font-size: 1.4rem;
        }

        @include media(tb) {
          font-size: 1.3rem;

          .tb-none {
            display: none;
          }
        }

        @include media(sp) {
          font-size: 1.4rem;
        }

        @include media(se) {
          font-size: 1.3rem;
        }

        &>p {
          font-size: 1.8rem;
          line-height: 1.8;

          @include media(pc-l) {
            font-size: 1.4rem;
          }

          @include media(tb) {
            font-size: 1.3rem;

            .tb-none {
              display: none;
            }
          }

          @include media(sp) {
            font-size: 1.4rem;
          }

          @include media(se) {
            font-size: 1.3rem;
          }
        }
      }

      &__note {
        margin-top: 12px;
        font-size: 1.6rem;
        line-height: 1.6;

        @include media(pc-l) {
          margin-top: 8px;
          font-size: 1.3rem;
        }

        @include media(tb) {
          margin-top: 6px;
          font-size: 1.1rem;
        }

        @include media(sp) {
          font-size: 1.2rem;
        }

        @include media(se) {
          font-size: 1.1rem;
        }
      }
    }
  }


  // カレーうどん
  // -------------------------------------------
  .menu-row {
    margin-bottom: 220px;

    @include media(pc-l) {
      margin-bottom: 195px;
    }

    @include media(tb) {
      margin-bottom: 150px;
    }

    @include media(sp) {
      margin-bottom: 130px;
    }
  }

  &__text {
    margin-bottom: 70px;
    font-size: 2.2rem;
    line-height: 2;
    color: $color-gold01;
    text-align: center;

    @include media(pc-l) {
      margin-bottom: 60px;
      font-size: 2rem;
    }

    @include media(tb) {
      margin-bottom: 50px;
      font-size: 1.8rem;
    }

    @include media(sp) {
      margin-bottom: 40px;
      font-size: 1.5rem;
    }

    @include media(se) {
      font-size: 1.4rem;
    }

    .small {
      font-size: 1.8rem;

      @include media(pc-l) {
        font-size: 1.6rem;
      }

      @include media(sp) {
        font-size: 1.3rem;
      }
    }
  }


  // その他のカレーうどん
  // -------------------------------------------
  .menu-etc {

    &__title {
      margin-bottom: 60px;
      padding-bottom: 17px;
      border-bottom: 1px solid $color-wh;
      font-size: 2.6rem;

      @include media(pc-l) {
        margin-bottom: 53px;
        padding-bottom: 15px;
        font-size: 2.3rem;
      }

      @include media(tb) {
        margin-bottom: 46px;
        padding-bottom: 12px;
        font-size: 1.9rem;
      }

      @include media(sp) {
        margin-bottom: 36px;
        font-size: 1.6rem;
      }
    }

    &__text {
      position: relative;
      padding-left: 26px;
      font-size: 3rem;

      @include media(pc-l) {
        padding-left: 20px;
        font-size: 2.2rem;
      }

      @include media(tb) {
        padding-left: 14px;
        font-size: 1.9rem;
      }

      @include media(sp) {
        font-size: 1.8rem;
      }

      @include media(se) {
        font-size: 1.7rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 4px;
        height: 40px;
        background-image: url(../images/common/icon/line03.svg);
        background-repeat: no-repeat;
        background-size: cover;

        @include media(pc-l) {
          height: 34px;
        }

        @include media(tb) {
          width: 3px;
          height: 30px;
        }

        @include media(tb) {
          height: 26px;
        }
      }

      .small {
        font-size: 1.8rem;
        vertical-align: middle;

        @include media(pc-l) {
          font-size: 1.5rem;
        }

        @include media(tb) {
          position: absolute;
          left: 10px;
          bottom: -24px;
          display: block;
          font-size: 1.3rem;
        }
      }
    }
  }


  // 定食
  // -------------------------------------------
  .menu-set {
    margin-top: -100px;
    padding-top: 100px;
    margin-bottom: 200px;

    @include media(pc-l) {
      margin-top: -80px;
      padding-top: 80px;
      margin-bottom: 176px;
    }

    @include media(pc-s) {
      margin-top: -220px;
      padding-top: 220px;
    }

    @include media(tb) {
      margin-top: -170px;
      padding-top: 170px;
      margin-bottom: 140px;
    }

    @include media(sp) {
      margin-top: -110px;
      padding-top: 110px;
      margin-bottom: 110px;
    }

    &__image {
      max-width: 810px;
      margin: 0 auto 48px;

      @include media(pc-l) {
        max-width: 720px;
        margin-bottom: 44px;
      }

      @include media(tb) {
        max-width: 520px;
        margin-bottom: 30px;
      }

      @include media(sp) {
        margin-bottom: 24px;
      }
    }

    &__title {
      font-size: 3.2rem;
      text-align: center;

      @include media(pc-l) {
        font-size: 2.6rem;
      }

      @include media(tb) {
        font-size: 2.2rem;
      }

      @include media(sp) {
        font-size: 2rem;
      }

      @include media(se) {
        font-size: 1.8rem;
      }
    }
  }


  // 一品メニュー
  // -------------------------------------------
  .menu-one {

    &__text {
      margin-top: 77px;
      font-size: 3rem;
      text-align: center;

      @include media(pc-l) {
        margin-top: 65px;
        font-size: 2.5rem;
      }

      @include media(tb) {
        margin-top: 56px;
        font-size: 2rem;
      }

      @include media(sp) {
        margin-top: 40px;
        font-size: 1.7rem;
      }
    }
  }

}
