/* 味味香のこだわり
------------------------------------------------ */
.commit {

  .commit-head {
    position: relative;
    background-image: url(../images/commit/commit_bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 150px 0 120px;
    text-align: center;
    @include media(pc-l) {
      padding: 132px 0 100px;
    }
    @include media(tb) {
      padding: 100px 0 80px;
    }
    @include media(sp) {
      padding: 70px 0 50px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#c79f62, .7);
    }

    &__container {
      position: relative;
    }

    &__icon {
      width: 154px;
      margin: 0 auto 44px;
      @include media(pc-l) {
        width: 136px;
        margin-bottom: 40px;
      }
      @include media(tb) {
        width: 100px;
        margin-bottom: 30px;
      }
      @include media(sp) {
        width: 70px;
        margin-bottom: 24px;
      }
    }

    &__comment {
      margin-bottom: 36px;
      font-size: 6rem;
      @include media(pc-l) {
        margin-bottom: 30px;
        font-size: 5.3rem;
      }
      @include media(tb) {
        margin-bottom: 24px;
        font-size: 4rem;
      }
      @include media(sp) {
        margin-bottom: 18px;
        font-size: 3.2rem;
      }
    }

    &__text {
      font-size: 2.2rem;
      line-height: 2;
      @include media(pc-l) {
        font-size: 1.9rem;
      }
      @include media(tb) {
        font-size: 1.5rem;
      }
      @include media(sp) {
        font-size: 1.4rem;
      }
    }
  }

  .commit-inner {
    padding: 124px 0 250px;
    @include media(pc-l) {
      padding: 110px 0 220px;
    }
    @include media(tb) {
      padding: 100px 0 180px;
    }
    @include media(sp) {
      padding: 80px 0 120px;
    }
  }

  &__comment {
    margin-bottom: 120px;
    font-size: 2.2rem;
    line-height: 2.2;
    text-align: center;
    @include media(pc-l) {
      margin-bottom: 110px;
      font-size: 1.9rem;
    }
    @include media(tb) {
      margin-bottom: 84px;
      font-size: 1.7rem;
    }
    @include media(sp) {
      margin-bottom: 66px;
      font-size: 1.4rem;
    }
    @include media(se) {
      font-feature-settings: "palt";
    }
  }

  .commit-row {
    margin-bottom: 150px;
    text-align: center;
    @include media(pc-l) {
      margin-bottom: 130px;
    }
    @include media(tb) {
      margin-bottom: 100px;
    }
    @include media(sp) {
      margin-bottom: 80px;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &__image {
    margin-bottom: 70px;
    @include media(pc-l) {
      margin-bottom: 64px;
    }
    @include media(tb) {
      margin-bottom: 50px;
    }
    @include media(sp) {
      margin-bottom: 40px;
    }
  }

  &__title {
    margin-bottom: 44px;
    font-size: 4.4rem;
    color: $color-gold02;
    @include media(pc-l) {
      margin-bottom: 40px;
      font-size: 3.6rem;
    }
    @include media(tb) {
      margin-bottom: 30px;
      font-size: 3rem;
    }
    @include media(sp) {
      margin-bottom: 24px;
      font-size: 2.4rem;
    }
    @include media(se) {
      font-feature-settings: "palt";
    }

    &--mb {
      margin-bottom: 62px;

      @include media(pc-l) {
        margin-bottom: 54px;
      }
      @include media(tb) {
        margin-bottom: 46px;
      }
      @include media(sp) {
        margin-bottom: 38px;
      }
    }
  }

  &__text {
    margin-bottom: 40px;
    font-size: 2rem;
    line-height: 2.2;
    @include media(pc-l) {
      margin-bottom: 34px;
      font-size: 1.7rem;
    }
    @include media(tb) {
      margin-bottom: 30px;
      font-size: 1.6rem;
    }
    @include media(sp) {
      margin-bottom: 24px;
      font-size: 1.4rem;
    }
    @include media(se) {
      font-feature-settings: "palt";
    }
  }

  &__subtitle01 {
    margin-bottom: 30px;
    font-size: 3rem;
    color: $color-gold02;
    @include media(pc-l) {
      margin-bottom: 25px;
      font-size: 2.5rem;
    }
    @include media(tb) {
      margin-bottom: 20px;
      font-size: 2.2rem;
    }
    @include media(sp) {
      margin-bottom: 14px;
      font-size: 1.7rem;
      line-height: 1.6;
    }
  }

  &__subtext {
    font-size: 2rem;
    @include media(pc-l) {
      font-size: 1.8rem;
    }
    @include media(tb) {
      font-size: 1.5rem;
    }
    @include media(sp) {
      font-size: 1.3rem;
    }
  }

  &__subtitle02 {
    font-size: 2.6rem;
    color: $color-gold02;
    @include media(pc-l) {
      font-size: 2.1rem;
    }
    @include media(tb) {
      font-size: 1.8rem;
      line-height: 1.8;
    }
    @include media(sp) {
      font-size: 1.5rem;
    }
    @include media(se) {
      font-size: 1.4rem;
      font-feature-settings: "palt";
    }
  }

  &__icon {
    width: 77px;
    margin: 0 auto 148px;
    @include media(pc-l) {
      width: 70px;
      margin-bottom: 130px;
    }
    @include media(tb) {
      width: 62px;
      margin-bottom: 114px;
    }
    @include media(sp) {
      width: 50px;
      margin-bottom: 90px;
    }
  }

  .commit-area {
    padding: 0 80px;
    @include media(pc-l) {
      padding: 0 30px;
    }
    @include media(pc-s) {
      padding: 0 20px;
    }
    @include media(tb) {
      padding: 0 10px;
    }
    @include media(sp) {
      padding: 0;
    }

    .commit-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: -40px;
      margin-bottom: 40px;
      margin-left: -40px;
      @include media(pc-l) {
        margin-top: -35px;
        margin-bottom: 35px;
        margin-left: -35px;
      }
      @include media(tb) {
        margin-top: -25px;
        margin-bottom: 25px;
        margin-left: -25px;
      }
      @include media(sp) {
        margin-top: -20px;
        margin-bottom: 20px;
        margin-left: -20px;
      }
      @include media(se) {
        margin-top: -10px;
        margin-bottom: 20px;
        margin-left: -10px;
      }
  
      &__item {
        width: calc(25% - 40px);
        height: 320px;
        background-color: $color-wh;
        margin-top: 40px;
        margin-left: 40px;
        @include media(pc-l) {
          width: calc(25% - 35px);
          height: 284px;
          margin-top: 35px;
          margin-left: 35px;
        }
        @include media(tb) {
          width: calc(33.333% - 25px);
          height: 250px;
          margin-top: 25px;
          margin-left: 25px;
        }
        @include media(sp) {
          width: calc(50% - 20px);
          height: 240px;
          margin-top: 20px;
          margin-left: 20px;
        }
        @include media(se) {
          width: calc(50% - 10px);
          height: 220px;
          margin-top: 10px;
          margin-left: 10px;
        }
      }

      .commit-list-head {
        position: relative;
        height: 50%;
        padding-top: 30px;
        @include media(pc-l) {
          padding-top: 24px;
        }
        @include media(tb) {
          padding-top: 20px;
        }
        @include media(sp) {
          padding-top: 18px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 16px;
          height: 2px;
          background-color: $color-bk;
          @include media(sp) {
            width: 14px;
            height: 1px;
          }
        }

        &__num {
          margin-bottom: 18px;
          font-size: 3rem;
          @include media(pc-l) {
            margin-bottom: 15px;
            font-size: 2.6rem;
          }
          @include media(tb) {
            margin-bottom: 15px;
            font-size: 2.2rem;
          }
          @include media(sp) {
            font-size: 2rem;
          }
          @include media(se) {
            margin-bottom: 12px;
            font-size: 1.8rem;
          }
        }
  
        &__title {
          margin-top: 14%;
          font-size: 2.4rem;
          line-height: 1.4;
          color: $color-gold02;
          @include media(pc-l) {
            font-size: 2rem;
          }
          @include media(tb) {
            font-size: 1.8rem;
          }
          @include media(sp) {
            margin-top: 16%;
            font-size: 1.7rem;
          }
          @include media(se) {
            font-size: 1.6rem;
          }

          &--none {
            margin-top: 0 !important;
          }
        }
      }

      &__text {
        padding-top: 20px;
        font-size: 1.7rem;
        font-family: $font-g;
        line-height: 1.8;
        @include media(pc-l) {
          padding-top: 17px;
          font-size: 1.5rem;
        }
        @include media(tb) {
          padding-top: 14px;
          font-size: 1.3rem;
        }
        @include media(se) {
          font-size: 1.2rem;
        }
      }
    }

    &__note {
      font-size: 1.8rem;
      font-family: $font-g;
      text-align: left;
      @include media(pc-l) {
        font-size: 1.6rem;
      }
      @include media(tb) {
        font-size: 1.3rem;
      }
    }
  }
}