/* メインの設定(全体)
------------------------------- */

/* wrapper */
.wrapper {
  max-width: 880px;
  margin: 0 auto;
  @include media(pc-l) {
    max-width: 880px;
  }
  @include media(pc-s) {
    max-width: 880px;
  }
  @include media(tb) {
    max-width: initial;
    padding: 0 50px;
  }
  @include media(sp) {
    padding: 0 20px;
  }
  @include media(se) {
    padding: 0 20px;
  }
}

// br関連
.d-tb,
.d-sp,
.d-se {
  display: none;
}

@include media(tb) {
  .d-tb {
    display: block;
  }
}

@include media(sp) {
  .d-pc {
    display: none;
  }
  .d-sp {
    display: block;
  }
}

@include media(se) {
  .d-se {
    display: block;
  }
}