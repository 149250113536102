/* 採用情報
------------------------------------------------ */
.recruit {

  .recruit-inner {
    padding: 260px 0;
    text-align: center;
    @include media(pc-l) {
      padding: 230px 0;
    }
    @include media(pc-s) {
      padding: 160px 0 230px;
    }
    @include media(tb) {
      padding: 130px 0 160px;
    }
    @include media(sp) {
      padding: 70px 0 120px;
    }
  }

  .recruit-head {
    position: relative;
    margin-bottom: 120px;
    padding: 88px 0 72px;
    border: 2px solid $color-red;
    @include media(pc-l) {
      margin-bottom: 110px;
      padding: 80px 0 64px;
    }
    @include media(tb) {
      margin-bottom: 90px;
      padding: 64px 0 56px;
    }
    @include media(sp) {
      margin-bottom: 50px;
      padding: 36px 0 30px;
      border-width: 1px;
    }

    .hide {
      position: absolute;
      display: block;
      width: 40px;
      height: 40px;
      background-color: $color-wh;
      @include media(tb) {
        width: 30px;
        height: 30px;
      }
      @include media(sp) {
        width: 16px;
        height: 16px;
      }

      &--01 {
        top: -2px;
        left: -2px;
        @include media(sp) {
          top: -1px;
          left: -1px;
        }
      }
      &--02 {
        top: -2px;
        right: -2px;
        @include media(sp) {
          top: -1px;
          right: -1px;
        }
      }
      &--03 {
        bottom: -2px;
        right: -2px;
        @include media(sp) {
          bottom: -1px;
          right: -1px;
        }
      }
      &--04 {
        bottom: -2px;
        left: -2px;
        @include media(sp) {
          bottom: -1px;
          left: -1px;
        }
      }
    }

    &__title {
      margin-bottom: 57px;
      font-size: 7.2rem;
      @include media(pc-l) {
        margin-bottom: 50px;
        font-size: 5.8rem;
      }
      @include media(tb) {
        margin-bottom: 34px;
        font-size: 4rem;
      }
      @include media(sp) {
        margin-bottom: 20px;
        font-size: 2.4rem;
      }
      @include media(se) {
        font-size: 2rem;
      }

      .big {
        display: block;
        margin-top: 48px;
        font-size: 10rem;
        @include media(pc-l) {
          margin-top: 42px;
          font-size: 8.8rem;
        }
        @include media(tb) {
          margin-top: 22px;
          font-size: 6.2rem;
        }
        @include media(sp) {
          margin-top: 16px;
          font-size: 4rem;
        }
        @include media(se) {
          margin-top: 12px;
          font-size: 2.8rem;
        }
      }
    }

    &__note {
      width: 320px;
      background-color: $color-red;
      margin: 0 auto;
      padding: 16px 0;
      border-radius: 0.3em;
      font-size: 3.8rem;
      color: $color-wh;
      @include media(pc-l) {
        width: 284px;
        font-size: 3.4rem;
      }
      @include media(tb) {
        width: 220px;
        padding: 14px 0;
        font-size: 2.6rem;
      }
      @include media(sp) {
        width: 140px;
        padding: 12px 0;
        font-size: 1.8rem;
      }
      @include media(se) {
        width: 130px;
        padding: 10px 0;
        font-size: 1.4rem;
      }
    }
  }

  .recruit-main {
    margin-bottom: 120px;
    @include media(pc-l) {
      margin-bottom: 106px;
    }
    @include media(tb) {
      margin-bottom: 90px;
    }
    @include media(sp) {
      margin-bottom: 60px;
    }

    &__text {
      margin-bottom: 110px;
      font-size: 2rem;
      line-height: 2.2;
      @include media(pc-l) {
        margin-bottom: 96px;
        font-size: 1.8rem;
      }
      @include media(tb) {
        margin-bottom: 60px;
        font-size: 1.6rem;
      }
      @include media(sp) {
        margin-bottom: 40px;
        font-size: 1.4rem;
      }
      @include media(se) {
        font-size: 1.2rem;
      }
    }

    &__title {
      margin-bottom: 28px;
      font-size: 2.8rem;
      @include media(pc-l) {
        margin-bottom: 24px;
        font-size: 2.5rem;
      }
      @include media(tb) {
        margin-bottom: 18px;
        font-size: 2rem;
      }
      @include media(sp) {
        margin-bottom: 14px;
        font-size: 1.6rem;
      }
      @include media(se) {
        margin-bottom: 10px;
        font-size: 1.4rem;
      }
    }

    &__time {
      font-size: 2.2rem;
      @include media(pc-l) {
        font-size: 2rem;
      }
      @include media(tb) {
        font-size: 1.6rem;
      }
      @include media(sp) {
        font-size: 1.5rem;
        line-height: 1.8;
      }
      @include media(se) {
        font-size: 1.3rem;
      }
    }
  }

  .recruit-contact {
    padding: 55px 0;
    border-top: 2px solid $color-red;
    border-bottom: 2px solid $color-red;
    @include media(pc-l) {
      padding: 48px 0;
    }
    @include media(tb) {
      padding: 38px 0;
    }
    @include media(sp) {
      padding: 30px 0;
      border-width: 1px;
    }

    &__tel {
      margin-bottom: 30px;
      font-size: 4.8rem;
      letter-spacing: 0.1em;
      @include media(pc-l) {
        margin-bottom: 27px;
        font-size: 4.2rem;
      }
      @include media(tb) {
        margin-bottom: 18px;
        font-size: 3.2rem;
      }
      @include media(sp) {
        margin-bottom: 14px;
        font-size: 2.6rem;
      }
      @include media(se) {
        margin-bottom: 12px;
        font-size: 2.2rem;
      }
    }

    &__name {
      font-size: 2.2rem;
      @include media(pc-l) {
        font-size: 2rem;
      }
      @include media(tb) {
        font-size: 1.7rem;
      }
      @include media(sp) {
        font-size: 1.5rem;
      }
      @include media(se) {
        font-size: 1.3rem;
      }
    }
  }
}