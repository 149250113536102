/* header
------------------------------------------------ */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  .header-side {
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 260px;
    height: 100vh;
    background-color: $color-gold01;
    text-align: center;
    @include media(pc-l) {
      width: 230px;
    }
    @include media(pc-s) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100vw;
      height: 140px;
    }
    @include media(tb) {
      height: 110px;
    }
    @include media(sp) {
      height: 70px;
    }

    .drawer-btn {
      width: 100%;
      margin-top: 20px;
      padding: 50px 0 80px;
      cursor: pointer;
      @include media(pc-s) {
        position: absolute;
        right: 5%;
        width: auto;
        margin-top: 0;
        padding: 0;
      }
          
      .menu-trigger,
      .menu-trigger span {
        display: inline-block;
        transition: all .4s;
        box-sizing: border-box;
      }

      .menu-trigger {
        position: relative;
        width: 50px;
        height: 44px;
        @include media(sp) {
          width: 30px;
          height: 23px;
        }

        span {
          position: absolute;
          left: 0;
          width: 100%;
          height: 5px;
          background-color: $color-bk;
          border-radius: 4px;
          @include media(sp) {
            height: 3px;
            border-radius: 2px;
          }

          &:nth-of-type(1) {
            top: 0;
          }
          &:nth-of-type(2) {
            top: 20px;
            @include media(sp) {
              top: 10px;
            }
          }
          &:nth-of-type(3) {
            bottom: 0;
          }
        }
      }

      &.active { // .drawer_btnに.activeがある場合

        .menu-trigger span {

          &:nth-of-type(1) {
            transform: translateY(19px) rotate(-45deg);
            @include media(sp) {
              transform: translateY(10px) rotate(-45deg);
            }
          }
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:nth-of-type(3) {
            transform: translateY(-20px) rotate(45deg);
            @include media(sp) {
              transform: translateY(-10px) rotate(45deg);
            }
          }
        }
      }

      &:not(.active):hover {  // .drawer_btnに.activeがない場合

        .menu-trigger span {

          &:nth-of-type(2) {
            width: 70%;
            @include media(tb) {
              width: 100%;
            }
          }
          &:nth-of-type(3) {
            width: 35%;
            @include media(tb) {
              width: 100%;
            }
          }
        }
      }
    }
    
    .logo{
        position: relative;
        width: 208px;
        padding-bottom: 50%;
        @include media(pc-l) {
          width: 184px;
        }
        @include media(pc-s) {
          order: 1;
          width: 318px;
          margin-right: 20px;
          margin-left: 5%;
          padding-bottom: 0;
        }
        @include media(tb) {
          width: 238px;
          margin-right: 14px;
        }
        @include media(sp) {
          width: 150px;
          margin-right: 8px;
        }

        a {
            display: block;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .shop{
        width: 160px;
        margin-bottom: 80px;
        padding: 7px 0;
        border: 1px solid $color-bk;
        border-radius: 0.3em;
        font-size: 2.4rem;
        @include media(pc-l) {
          width: 142px;
          margin-bottom: 70px;
          font-size: 2.2rem;
        }
        @include media(pc-s) {
          order: 2;
          width: 175px;
          margin-bottom: 0;
          font-size: 2.6rem;
        }
        @include media(tb) {
          width: 130px;
          padding: 6px 0;
          font-size: 2rem;
        }
        @include media(sp) {
          width: 82px;
          padding: 5px 0;
          font-size: 1.3rem;
        }
    }

    /* drawer
    --------------------------------------------------------------------------- */
    .drawer-menu{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      width: calc(100vw - 260px);
      background-color: rgba($color-wh, .9);
      margin-left: 260px;
      text-align: left;
      opacity: 0;
      z-index: -1;
      transition: all 0.7s;
      @include media(pc-l) {
        width: calc(100vw - 230px);
        margin-left: 230px;
      }
      @include media(pc-s) {
        transform: translateY(-100%);
        width: 100%;
        height: calc(100vh - 140px);
        margin-top: 140px;
        margin-left: 0;
      }
      @include media(tb) {
        height: calc(100vh - 110px);
        margin-top: 110px;
      }
      @include media(sp) {
        height: calc(100vh - 70px);
        margin-top: 70px;
      }

      &__text {
        margin-top: 80px;
        margin-bottom: 150px;
        padding-left: 100px;
        font-size: 2.8rem;
        font-family: $font-en;
        color: $color-gold01;
        @include media(pc-l) {
          margin-top: 66px;
          margin-bottom: 130px;
          padding-left: 90px;
          font-size: 2.5rem;
        }
        @include media(pc-s) {
          display: none;
        }
      }

      .gnav {
        @include media(pc-s) {
          margin-top: 50px;
        }
        @include media(tb) {
          margin-top: 30px;
        }
        @include media(sp) {
          margin-top: 10px;
        }

        &__list {
          font-size: 2.2rem;
          @include media(pc-l) {
            font-size: 2rem;
          }
          @include media(pc-s) {
            font-size: 2.4rem;
          }
          @include media(tb) {
            font-size: 2rem;
          }
          @include media(sp) {
            font-size: 1.8rem;
          }

          a {
            display: block;
            padding: 24px 0 24px 100px;
            @include media(pc-l) {
              padding: 20px 0 20px 90px;
            }
            @include media(pc-s) {
              padding: 22px 0 22px 8%;
            }
            @include media(sp) {
              padding: 20px 0 20px 8%;
            }

            &:hover {
              background-color: $color-wh;
            }
          }
        }
        
      }

      &.open {
        transform: translate(0);
        opacity: 1;
      }
    }
  }
}


/* スマホ横
------------------------------------------------ */
@media only screen and (orientation:landscape) and (max-width:813px){
  .header {
  
    .header-side {
      height: 70px;
  
      .drawer-btn {
        position: absolute;
        right: 5%;
  
        .menu-trigger {
          width: 30px;
          height: 23px;

          span {
            height: 3px;
            border-radius: 2px;

            &:nth-of-type(2) {
              top: 10px;
            }
          }
        }
  
        &.active { // .drawer_btnに.activeがある場合
  
          .menu-trigger span {
  
            &:nth-of-type(1) {
              transform: translateY(10px) rotate(-45deg);
            }
            &:nth-of-type(3) {
              transform: translateY(-10px) rotate(45deg);
            }
          }
        }
      }
      
      .logo{
        width: 150px;
        margin-right: 8px;
      }
  
      .shop{
        width: 82px;
        padding: 5px 0;
        font-size: 1.3rem;
      }
  
      /* drawer
      --------------------------------------------------------------------------- */
      .drawer-menu{
        height: calc(100vh - 70px);
        margin-top: 70px;
        padding-bottom: 50px;
        overflow: auto;
  
        .gnav {
          margin-top: 20px;
  
          &__list {
            font-size: 1.8rem;
  
            a {
              padding: 20px 0 20px 8%;
            }
          }   
        }
      }
    }
  }
}