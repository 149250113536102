/* ページトップ
------------------------------------------------ */
.pagetop {
  position: fixed;
  right: 4%;
  bottom: 6%;
  display: none;
  z-index: 1;

  @include media(tb) {
    bottom: 3%;
  }

  &.-takeout {
    bottom: 14%;

    @include media(pc-l) {
      bottom: 12%;
    }

    @include media(sp) {
      bottom: 10%;
    }
  }

  a {
    position: relative;
    display: block;
    width: 64px;
    height: 64px;
    background-color: $color-bk;
    border: 1px solid $color-bk;
    border-radius: 50%;
    box-shadow: 0 0 8px #666;

    @include media(sp) {
      width: 58px;
      height: 58px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 26px;
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
      width: 20px;
      height: 20px;
      border-top: 3px solid $color-gold01;
      border-right: 3px solid $color-gold01;
      transition: .3s;

      @include media(sp) {
        top: 24px;
        width: 18px;
        height: 18px;
      }
    }

    &:hover {

      &::before {
        transform: translate(-50%, -6px) rotate(-45deg);

        @include media(tb) {
          transform: translate(-50%, 0) rotate(-45deg);
        }
      }
    }
  }
}
