/* English
------------------------------------------------ */
.eng {
  font-feature-settings: "palt";
  overflow: hidden;

  .eng-head {
    position: relative;
    background-image: url(../images/eng/eng_bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 210px 0 180px;
    text-align: center;
    @include media(pc-l) {
      padding: 186px 0 158px;
    }
    @include media(tb) {
      padding: 150px 0 130px;
    }
    @include media(sp) {
      padding: 100px 0 80px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#e5e5e5, .7);
    }

    &__container {
      position: relative;
    }

    &__title {
      margin-bottom: 40px;
      font-size: 7rem;
      @include media(pc-l) {
        margin-bottom: 38px;
        font-size: 6.2rem;
      }
      @include media(tb) {
        margin-bottom: 26px;
        font-size: 4.5rem;
      }
      @include media(sp) {
        margin-bottom: 18px;
        font-size: 3.8rem;
      }
    }

    &__text {
      font-size: 2.4rem;
      @include media(pc-l) {
        font-size: 2.2rem;
      }
      @include media(tb) {
        font-size: 1.8rem;
      }
      @include media(sp) {
        font-size: 1.6rem;
        line-height: 1.5;
      }
    }
  }

  .eng-inner {
    padding-top: 108px;
    @include media(pc-l) {
      padding-top: 90px;
    }
    @include media(tb) {
      padding-top: 80px;
    }
    @include media(sp) {
      padding-top: 60px;
    }
  }

  &__text {
    margin-bottom: 100px;
    font-size: 2.4rem;
    font-family: $font-en;
    line-height: 2.2;
    color: $color-gold02;
    @include media(pc-l) {
      margin-bottom: 88px;
      font-size: 2.2rem;
    }
    @include media(tb) {
      margin-bottom: 78px;
      font-size: 2rem;
    }
    @include media(sp) {
      margin-bottom: 56px;
      font-size: 1.7rem;
    }
  }

  &__image {
    margin-bottom: 84px;
    @include media(pc-l) {
      margin-bottom: 74px;
    }
    @include media(tb) {
      margin-bottom: 54px;
    }
    @include media(sp) {
      margin-bottom: 40px;
    }
  }

  .what {
    margin-bottom: 124px;
    text-align: center;
    @include media(pc-l) {
      margin-bottom: 110px;
    }
    @include media(tb) {
      margin-bottom: 96px;
    }
    @include media(sp) {
      margin-bottom: 70px;
    }

    &__title {
      position: relative;
      display: inline-block;
      margin-bottom: 40px;
      padding: 0 36px 10px;
      font-size: 4.4rem;
      @include media(pc-l) {
        margin-bottom: 36px;
        padding: 0 30px 10px;
        font-size: 3.9rem;
      }
      @include media(tb) {
        margin-bottom: 30px;
        padding: 0 24px 10px;
        font-size: 3.4rem;
      }
      @include media(sp) {
        margin-bottom: 24px;
        padding: 0 12px 8px;
        font-size: 2.8rem;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 4px;
        background-color: $color-bk;
        @include media(sp) {
          height: 2px;
        }
      }
    }

    &__text {
      font-size: 2.2rem;
      line-height: 2.2;
      text-align: left;
      @include media(pc-l) {
        font-size: 2rem;
      }
      @include media(tb) {
        font-size: 1.7rem;
      }
      @include media(sp) {
        font-size: 1.5rem;
      }
    }
  }

  .good {
    margin-bottom: 160px;
    @include media(pc-l) {
      margin-bottom: 140px;
    }
    @include media(tb) {
      margin-bottom: 120px;
    }
    @include media(sp) {
      margin-bottom: 80px;
    }

    &__title {
      margin-bottom: 60px;
      font-size: 3.4rem;
      font-family: $font-en;
      color: $color-gold02;
      @include media(pc-l) {
        margin-bottom: 54px;
        font-size: 3rem;
      }
      @include media(tb) {
        margin-bottom: 40px;
        font-size: 2.7rem;
      }
      @include media(sp) {
        margin-bottom: 24px;
        font-size: 1.9rem;
      }
    }

    .good-list {

      &__item {
        margin-bottom: 60px;
        @include media(pc-l) {
          margin-bottom: 54px;
        }
        @include media(tb) {
          margin-bottom: 44px;
        }
        @include media(sp) {
          
        }

        &--last {
          margin-bottom: 0 !important;
        }
      }

      .row {
        display: flex;
        @include media(sp) {
          display: block;
        }

        &__image {
          width: 320px;
          margin-right: 40px;
          @include media(pc-l) {
            width: 284px;
            margin-right: 30px;
          }
          @include media(tb) {
            width: 250px;
            margin-right: 26px;
          }
          @include media(sp) {
            width: 100%;
            margin-right: 0;

            img {
              max-width: none;
              width: 100%;
            }
          }
        }

        .row-box {
          flex: 1;
          padding-top: 24px;
          @include media(pc-l) {
            padding-top: 22px;
          }
          @include media(tb) {
            padding-top: 16px;
          }
          @include media(sp) {
            padding-top: 20px;
          }

          &__title {
            padding-bottom: 0.5em;
            font-size: 3.8rem;
            @include media(pc-l) {
              font-size: 3.2rem;
            }
            @include media(tb) {
              font-size: 2.4rem;
            }
            @include media(sp) {
              
            }
          }
    
          &__text {
            font-size: 2.2rem;
            line-height: 2.2;
            @include media(pc-l) {
              font-size: 1.7rem;
            }
            @include media(tb) {
              font-size: 1.6rem;
            }
            @include media(sp) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  .ivo {
    margin: 0 -200%;
    padding: 150px 200% 170px;
    background-color: $color-ivo;
    text-align: center;
    @include media(pc-l) {
      padding: 136px 200% 150px;
    }
    @include media(tb) {
      padding: 120px 200% 62px;
    }
    @include media(sp) {
      padding: 80px 200% 20px;
    }

    &__title {
      margin-bottom: 40px;
      font-size: 5.8rem;
      color: $color-gold02;
      @include media(pc-l) {
        margin-bottom: 36px;
        font-size: 5rem;
      }
      @include media(tb) {
        margin-bottom: 28px;
        font-size: 4.4rem;
      }
      @include media(sp) {
        margin-bottom: 20px;
        font-size: 3rem;
      }
    }

    &__cate {
      position: relative;
      display: inline-block;
      margin-bottom: 82px;
      padding: 0 96px;
      font-size: 4rem;
      font-family: $font-en;
      @include media(pc-l) {
        margin-bottom: 73px;
        font-size: 3.6rem;
      }
      @include media(tb) {
        margin-bottom: 64px;
        font-size: 3rem;
      }
      @include media(sp) {
        margin-bottom: 40px;
        padding: 0 54px;
        font-size: 2.2rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 72px;
        height: 6px;
        background-image: url(../images/common/icon/line04.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include media(sp) {
          width: 40px;
          height: 5px;
        }
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 72px;
        height: 6px;
        background-image: url(../images/common/icon/line04.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include media(sp) {
          width: 40px;
          height: 5px;
        }
      }
    }

    .ivo-area {
      position: relative;
      margin-bottom: 78px;
      padding: 50px 90px 36px;
      border: 1px solid $color-bk;
      @include media(pc-l) {
        margin-bottom: 70px;
        padding: 40px 70px 32px;
      }
      @include media(tb) {
        margin-bottom: 50px;
        padding: 36px 50px 30px;
      }
      @include media(sp) {
        margin-bottom: 40px;
        padding: 30px 34px 26px;
      }

      .hide {
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        background-color: $color-ivo;
        @include media(tb) {
          width: 15px;
          height: 15px;
        }
  
        &--01 {
          top: -1px;
          left: -1px;
        }
        &--02 {
          top: -1px;
          right: -1px;
        }
        &--03 {
          bottom: -1px;
          right: -1px;
        }
        &--04 {
          bottom: -1px;
          left: -1px;
        }
      }

      &__title {
        margin-bottom: 34px;
        font-size: 3.2rem;
        @include media(pc-l) {
          margin-bottom: 24px;
          font-size: 2.7rem;
        }
        @include media(tb) {
          margin-bottom: 20px;
          font-size: 2.5rem;
        }
        @include media(sp) {
          margin-bottom: 16px;
          font-size: 2.2rem;
        }
      }

      &__text {
        font-size: 2.2rem;
        line-height: 2;
        text-align: left;
        @include media(pc-l) {
          font-size: 2rem;
        }
        @include media(tb) {
          font-size: 1.7rem;
        }
        @include media(sp) {
          font-size: 1.6rem;
        }
      }
    }

    &__note {
      margin-bottom: 80px;
      font-size: 2.8rem;
      font-family: $font-en;
      color: $color-red;
      @include media(pc-l) {
        margin-bottom: 70px;
        font-size: 2.5rem;
      }
      @include media(tb) {
        margin-bottom: 60px;
        font-size: 2rem;
      }
      @include media(sp) {
        margin-bottom: 80px;
        font-size: 1.8rem;
        line-height: 1.5;
      }
    }


    .ivo-list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -58px;
      @include media(pc-l) {
        margin-left: -44px;
      }
      @include media(tb) {
        margin-left: -40px;
      }
      @include media(sp) {
        margin-left: 0;
      }

      &__item {
        width: calc(33.333% - 58px);
        margin-left: 58px;
        @include media(pc-l) {
          width: calc(33.333% - 44px);
          margin-left: 44px;
        }
        @include media(tb) {
          width: calc(50% - 40px);
          margin-left: 40px;
        }
        @include media(sp) {
          width: 100%;
          margin-left: 0;
        }
      }

      .menuEn {
        text-align: left;

        &__image {
          img {
            max-width: none;
            width: 100%;
          }
        }

        .menuEn-box {
          min-height: 334px;
          padding-top: 20px;
          border-top: 8px solid $color-bk;
          @include media(pc-l) {
            min-height: 300px;
          }
          @include media(sp) {
            
          }

          &--none {
            border-top: none;
          }
        }

        &__title {
          margin-bottom: 10px;
          font-size: 2.8rem;
          line-height: 1.3;
          @include media(pc-l) {
            margin-bottom: 10px;
            font-size: 2.5rem;
          }
          @include media(sp) {
            margin-bottom: 10px;
            font-size: 2.8rem;
          }
        }

        &__text {
          position: relative;
          margin-bottom: 24px;
          padding-bottom: 30px;
          font-size: 1.7rem;
          @include media(pc-l) {
            font-size: 1.4rem;
          }
          @include media(sp) {
            font-size: 1.6rem;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 40px;
            height: 4px;
            background-image: url(../images/common/icon/line01.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
        }

        &__price {
          font-size: 3rem;
          font-family: $font-en;
          @include media(pc-l) {
            font-size: 2.7rem;
          }
        }

        &__vege {
          margin-top: 16px;
          padding: 7px 0;
          border: 1px solid $color-bk;
          border-radius: 0.2em;
          font-size: 1.8rem;
          font-family: $font-en;
          text-align: center;
          @include media(pc-l) {
            margin-top: 14px;
            padding: 6px 0;
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  .shopEn {
    padding-top: 140px;
    @include media(pc-l) {
      padding-top: 123px;
    }
    @include media(tb) {
      padding-top: 100px;
    }
    @include media(sp) {
      padding-top: 60px;
    }

    &__title {
      margin-bottom: 24px;
      font-size: 3.6rem;
      font-family: $font-en;
      @include media(pc-l) {
        margin-bottom: 20px;
        font-size: 3.2rem;
      }
      @include media(tb) {
        margin-bottom: 16px;
        font-size: 2.8rem;
      }
      @include media(sp) {
        margin-bottom: 10px;
        font-size: 2rem;
      }
    }

    &__cate {
      margin-bottom: 100px;
      font-size: 2.8rem;
      font-family: $font-en;
      @include media(pc-l) {
        margin-bottom: 88px;
        font-size: 2.5rem;
      }
      @include media(tb) {
        margin-bottom: 78px;
        font-size: 2.2rem;
      }
      @include media(sp) {
        margin-bottom: 50px;
        font-size: 1.8rem;
      }
    }

    .shopEn-box {
      margin-bottom: 50px;
      @include media(pc-l) {
        margin-bottom: 46px;
      }
      @include media(tb) {
        margin-bottom: 38px;
      }
      @include media(sp) {
        margin-bottom: 30px;
      }

      &--last {
        margin-bottom: 120px;
        @include media(pc-l) {
          margin-bottom: 110px;
        }
        @include media(tb) {
          margin-bottom: 96px;
        }
        @include media(sp) {
          margin-bottom: 50px;
        }
      }

      &__title {
        margin-bottom: 16px;
        font-size: 2.8rem;
        font-family: $font-en;
        color: $color-gold02;
        @include media(pc-l) {
          margin-bottom: 13px;
          font-size: 2.5rem;
        }
        @include media(tb) {
          margin-bottom: 10px;
          font-size: 2.2rem;
        }
        @include media(sp) {
          font-size: 1.8rem;
        }
      }

      &__text {
        font-size: 2.6rem;
        line-height: 2;
        @include media(pc-l) {
          font-size: 2.3rem;
        }
        @include media(tb) {
          font-size: 2rem;
        }
        @include media(sp) {
          font-size: 1.6rem;
        }
      }


      &__tel {
        font-size: 3.6rem;
        line-height: 1.8;
        @include media(pc-l) {
          font-size: 3.2rem;
        }
        @include media(tb) {
          font-size: 2.8rem;
        }
        @include media(sp) {
          font-size: 2.2rem;
        }
      }
    }
  }
}

.accessEn-map {
  padding-top: 58px;
  border-top: 1px solid $color-bk;
  @include media(pc-l) {
    padding-top: 52px;
  }
  @include media(tb) {
    padding-top: 46px;
  }
  @include media(sp) {
    padding-top: 36px;
  }

  &__title {
    margin-bottom: 57px;
    font-size: 3.6rem;
    font-family: $font-en;
    color: $color-gold02;
    text-align: center;
    @include media(pc-l) {
      margin-bottom: 50px;
      font-size: 3.2rem;
    }
    @include media(tb) {
      margin-bottom: 44px;
      font-size: 2.8rem;
    }
    @include media(sp) {
      margin-bottom: 34px;
      font-size: 2.4rem;
    }
  }

  & > iframe {
    width: 100%;
    height: 600px;
    @include media(pc-l) {
      height: 530px;
    }
    @include media(tb) {
      height: 470px;
    }
    @include media(sp) {
      height: 400px;
    }
  }
}