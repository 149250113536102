/* トップページ
------------------------------------------------ */

/* テイクアウトボタン
------------------------------------------------ */
.top-takeoutBtn {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;

  &>a {
    position: relative;
    display: block;
    width: 270px;
    z-index: 1;

    @include media(pc-l) {
      width: res(270px);
    }

    @include media(pc-s) {
      top: 140px;
    }

    @include media(tb) {
      top: 110px;
      width: 180px;
    }

    @include media(sp) {
      top: 70px;
      width: 117px;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      opacity: .8;

      @include media(tb) {
        opacity: 1;
      }
    }
  }
}

/* トップページ共通
------------------------------------------------ */
.top__title {
  margin-bottom: 60px;
  font-size: 4.2rem;
  color: $color-wh;
  text-align: center;

  @include media(pc-l) {
    margin-bottom: 46px;
    font-size: 3.7rem;
  }

  @include media(tb) {
    margin-bottom: 40px;
    font-size: 3.3rem;
  }

  @include media(sp) {
    margin-bottom: 34px;
    font-size: 3.8rem;
  }

  &--news {
    margin-bottom: 45px;
    font-size: 3.2rem;

    @include media(pc-l) {
      margin-bottom: 38px;
      font-size: 2.8rem;
    }

    @include media(tb) {
      margin-bottom: 35px;
      font-size: 2.5rem;
    }

    @include media(sp) {
      margin-bottom: 38px;
      font-size: 2.8rem;
    }
  }

  &--access {
    color: $color-bk;
  }

  .en {
    position: relative;
    margin-top: 20px;
    padding-top: 24px;
    font-size: 1.8rem;
    font-family: $font-en;
    letter-spacing: 0.2em;

    @include media(pc-l) {
      font-size: 1.6rem;
    }

    @include media(tb) {
      margin-top: 16px;
      padding-top: 22px;
      font-size: 1.4rem;
    }

    @include media(sp) {
      margin-top: 18px;
      padding-top: 24px;
      font-size: 1.6rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 4px;
      background-image: url(../images/common/icon/line01.svg);
      background-repeat: no-repeat;
      background-size: cover;

      @include media(tb) {
        width: 32px;
        height: 3px;
      }
    }
  }
}


/* ニュース
------------------------------------------------ */
.top-news {
  background-color: $color-bk;
  padding: 88px 0 80px;

  @include media(pc-l) {
    padding: 74px 0 70px;
  }

  @include media(tb) {
    padding: 68px 0 62px;
  }

  @include media(sp) {
    padding: 60px 0 50px;
  }

  &__list {
    position: relative;
  }

  &__item {
    border-bottom: 1px solid $color-wh;
    font-size: 1.8rem;
    font-family: $font-gb;
    font-weight: 600;

    @include media(pc-l) {
      font-size: 1.6rem;
    }

    @include media(tb) {
      font-size: 1.4rem;
    }

    @include media(sp) {
      font-size: 1.3rem;
    }

    &:first-child {
      border-top: 1px solid $color-wh;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      padding: 20px 0 20px 120px;
      color: $color-wh;

      @include media(pc-l) {
        padding: 18px 0 18px 110px;
      }

      @include media(tb) {
        padding: 18px 0 18px 95px;
      }

      @include media(sp) {
        display: block;
        padding: 15px 0 15px 80px;
      }

      &::before {
        content: 'NEW';
        position: absolute;
        top: 50%;
        left: 2%;
        transform: translateY(-50%);
        width: 74px;
        background-color: $color-wh;
        padding: 5px 0;
        font-size: 1.6rem;
        font-family: $font-en;
        color: $color-bk;
        letter-spacing: 0.2em;
        text-align: center;
        transition: .3s;

        @include media(pc-l) {
          width: 66px;
          padding: 4px 0 3px;
          font-size: 1.4rem;
        }

        @include media(tb) {
          width: 58px;
          padding: 3px 0 2px;
          font-size: 1.3rem;
        }

        @include media(sp) {
          width: 56px;
          font-size: 1.2rem;
        }
      }

      &:hover {
        background-color: $color-wh;
        color: $color-bk;

        &::before {
          background-color: $color-gold01;
          color: $color-wh;
        }
      }

      time {
        margin-right: 26px;
        font-size: 1.8rem;
        font-family: $font-en;
        color: $color-gold01;

        @include media(pc-l) {
          font-size: 1.6rem;
        }

        @include media(tb) {
          margin-right: 22px;
          font-size: 1.4rem;
        }

        @include media(sp) {
          display: block;
          margin-right: 0;
          margin-bottom: 6px;
          font-size: 1.3rem;
        }
      }
    }
  }

  &__btn {
    position: absolute;
    top: -80px;
    right: 2%;
    display: block;
    width: 164px;
    padding: 12px 0;
    border: 1px solid $color-wh;
    border-radius: 0.4em;
    font-size: 1.8rem;
    font-family: $font-g;
    color: $color-wh;
    text-align: center;

    @include media(pc-l) {
      top: -72px;
      width: 145px;
      padding: 10px 0;
      font-size: 1.6rem;
    }

    @include media(tb) {
      top: -62px;
      width: 130px;
      padding: 9px 0;
      font-size: 1.4rem;
    }

    @include media(sp) {
      position: static;
      width: 140px;
      margin: 34px auto 0;
      padding: 10px 0;
      font-size: 1.5rem;
    }

    &:hover {
      background-color: $color-wh;
      color: $color-bk;
    }
  }
}


/* ムービー
------------------------------------------------ */
.top-movie {
  background-color: $color-ivo;
  padding: 136px 0 180px;

  @include media(pc-l) {
    padding: 124px 0 160px;
  }

  @include media(tb) {
    padding: 110px 0 140px;
  }

  @include media(sp) {
    padding: 65px 0 72px;
  }

  &__title {
    position: relative;
    max-width: 360px;
    margin: 0 auto 75px;
    padding: 18px 0;
    border: 1px solid $color-red;
    font-size: 2.6rem;
    color: $color-red;
    text-align: center;

    @include media(pc-l) {
      max-width: 320px;
      margin-bottom: 66px;
      padding: 15px 0;
      font-size: 2.3rem;
    }

    @include media(tb) {
      max-width: 280px;
      margin-bottom: 60px;
      padding: 13px 0;
      font-size: 2rem;
    }

    @include media(sp) {
      margin-bottom: 42px;
    }

    @include media(se) {
      max-width: 240px;
      font-size: 1.8rem;
    }

    .hide {
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      background-color: $color-ivo;

      @include media(tb) {
        width: 8px;
        height: 8px;
      }

      &--01 {
        top: -1px;
        left: -1px;
      }

      &--02 {
        top: -1px;
        right: -1px;
      }

      &--03 {
        bottom: -1px;
        right: -1px;
      }

      &--04 {
        bottom: -1px;
        left: -1px;
      }
    }
  }

  &__video {
    max-width: 960px;
    margin: 0 auto;
    cursor: pointer;
    transition: .3s;

    @include media(pc-l) {
      max-width: 850px;
    }

    @include media(tb) {
      padding: 0 40px;
    }

    @include media(sp) {
      padding: 0 20px;
    }

    &:hover {
      box-shadow: 0 0 16px $color-gold02;

      @include media(tb) {
        box-shadow: none;
      }
    }

    video {
      width: 100%;
    }
  }
}


/* こだわり
------------------------------------------------ */
.top-commit {
  position: relative;
  background-image: url(../images/top/top_commit_bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 170px 0 160px;
  text-align: center;

  @include media(pc-l) {
    padding: 150px 0 140px;
  }

  @include media(tb) {
    padding: 132px 0 125px;
  }

  @include media(sp) {
    padding: 105px 0 92px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-gold01, .7);
  }

  .wrapper {
    position: relative;
  }

  &__icon {
    width: 154px;
    margin: 0 auto 25px;

    @include media(pc-l) {
      width: 136px;
      margin-bottom: 22px;
    }

    @include media(tb) {
      width: 120px;
      margin-bottom: 20px;
    }

    @include media(sp) {
      width: 110px;
    }
  }

  &__comment {
    margin-bottom: 18px;
    font-size: 3.4rem;
    line-height: 1.8;

    @include media(pc-l) {
      margin-bottom: 15px;
      font-size: 3rem;
    }

    @include media(tb) {
      margin-bottom: 13px;
      font-size: 2.7rem;
    }

    @include media(sp) {
      font-size: 2.4rem;
    }

    @include media(se) {
      font-size: 2.2rem;
    }
  }

  &__text {
    margin-bottom: 36px;
    font-size: 1.8rem;
    line-height: 2;

    @include media(pc-l) {
      margin-bottom: 30px;
      font-size: 1.6rem;
    }

    @include media(tb) {
      margin-bottom: 26px;
      font-size: 1.4rem;
    }

    @include media(sp) {
      margin-bottom: 23px;
      font-size: 1.5rem;
    }
  }

  &__btn {
    display: block;
    width: 360px;
    background-color: $color-wh;
    margin: 0 auto;
    padding: 24px 0;
    border-radius: 0.2em;
    font-size: 3rem;
    color: $color-gold02;
    text-align: center;

    @include media(pc-l) {
      width: 320px;
      padding: 22px 0;
      font-size: 2.7rem;
    }

    @include media(tb) {
      width: 282px;
      padding: 19px 0;
      font-size: 2.4rem;
    }

    @include media(sp) {
      width: 300px;
      padding: 20px 0;
      font-size: 2.5rem;
    }

    @include media(se) {
      width: 100%;
      font-size: 2.3rem;
    }

    &:hover {
      background-color: $color-bk;
    }
  }
}


/* おしながき
------------------------------------------------ */
.top-menu {
  background-color: $color-bk;
  padding: 185px 0 200px;

  @include media(pc-l) {
    padding: 165px 0 176px;
  }

  @include media(tb) {
    padding: 144px 0 156px;
  }

  @include media(sp) {
    padding: 74px 0 78px;
  }

  &__text {
    margin-bottom: 80px;
    font-size: 2.2rem;
    line-height: 2;
    color: $color-wh;
    text-align: center;

    @include media(pc-l) {
      margin-bottom: 70px;
      font-size: 2rem;
    }

    @include media(tb) {
      margin-bottom: 60px;
      font-size: 1.7rem;
    }

    @include media(sp) {
      margin-bottom: 32px;
    }

    @include media(se) {
      font-size: 1.6em;
    }
  }

  .top-menu-list {
    margin-bottom: 100px;

    @include media(pc-l) {
      margin-bottom: 88px;
    }

    @include media(tb) {
      margin-bottom: 78px;
    }

    @include media(sp) {
      margin-bottom: 50px;
    }

    &__item {
      margin-bottom: 80px;

      @include media(pc-l) {
        margin-bottom: 70px;
      }

      @include media(tb) {
        margin-bottom: 62px;
      }

      @include media(sp) {
        margin-bottom: 23px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .menu-row {
        display: flex;

        @include media(sp) {
          display: block;
        }

        &--rev {
          flex-direction: row-reverse;
        }

        &:hover {
          opacity: .7;

          @include media(tb) {
            opacity: 1;
          }
        }

        &>div {
          width: 50%;

          @include media(sp) {
            width: 100%;
          }
        }

        &__image {

          img {
            width: 100%;
            max-width: none;

            @include media(tb) {
              height: 236px;
              object-fit: cover;
              font-family: 'object-fit: cover;';
            }

            @include media(sp) {
              height: auto;
            }
          }

        }

        .menu-row-box {
          background-color: $color-wh;
          padding: 36px 60px 0 60px;

          @include media(pc-l) {
            padding: 36px 53px 0 53px;
          }

          @include media(tb) {
            padding: 26px 32px 0 32px;
          }

          @include media(sp) {
            padding: 30px 44px;
          }

          @include media(se) {
            padding: 26px 30px;
          }

          &__title {
            position: relative;
            margin-bottom: 24px;
            padding-bottom: 22px;
            font-size: 3rem;
            line-height: 1.6;

            @include media(pc-l) {
              font-size: 2.8rem;
            }

            @include media(tb) {
              margin-bottom: 20px;
              padding-bottom: 18px;
              font-size: 2.4rem;
            }

            @include media(sp) {
              margin-bottom: 20px;
              padding-bottom: 18px;
              font-size: 2.2rem;
            }

            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 40px;
              height: 4px;
              background-image: url(../images/common/icon/line01.svg);
              background-repeat: no-repeat;
              background-size: cover;

              @include media(tb) {
                width: 32px;
                height: 3px;
              }

              @include media(sp) {
                width: 30px;
              }
            }
          }

          &__text {
            font-size: 1.5rem;
            line-height: 1.8;

            @include media(tb) {
              font-size: 1.3rem;
              font-feature-settings: "palt";
            }

            @include media(sp) {
              font-feature-settings: normal;
            }

            @include media(se) {
              font-feature-settings: "palt";
            }
          }
        }
      }
    }
  }

  .top-menu-area {
    display: flex;
    margin-bottom: 70px;
    margin-left: -60px;
    padding: 0 30px;

    @include media(pc-l) {
      margin-bottom: 52px;
      margin-left: -54px;
      padding: 0 26px;
    }

    @include media(tb) {
      margin-bottom: 46px;
      margin-left: -47px;
      padding: 0 23px;
    }

    @include media(sp) {
      flex-wrap: wrap;
      margin-top: -30px;
      margin-bottom: 30px;
      margin-left: -30px;
      padding: 0 20px;
    }

    &__item {
      width: calc(25% - 60px);
      margin-left: 60px;

      @include media(pc-l) {
        width: calc(25% - 54px);
        margin-left: 54px;
      }

      @include media(tb) {
        width: calc(25% - 47px);
        margin-left: 47px;
      }

      @include media(sp) {
        width: calc(50% - 30px);
        margin-top: 30px;
        margin-left: 30px;
      }

      .menu-area {
        display: block;

        &:hover {
          opacity: .7;

          @include media(tb) {
            opacity: 1;
          }
        }

        &__image {
          margin-bottom: 10px;

          @include media(tb) {
            margin-bottom: 8px;
          }

          @include media(sp) {
            margin-bottom: 10px;

            img {
              max-width: none;
              width: 100%;
            }
          }

        }

        &__title {
          font-size: 1.5rem;
          line-height: 1.8;
          color: $color-wh;
          text-align: center;

          @include media(pc-l) {
            font-size: 1.4rem;
          }

          @include media(tb) {
            font-size: 1.3rem;
          }

          @include media(sp) {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  &__btn {
    display: block;
    width: 400px;
    margin: 0 auto;
    padding: 32px 0;
    border: 1px solid $color-wh;
    border-radius: 0.4em;
    font-size: 2rem;
    color: $color-wh;
    text-align: center;

    @include media(pc-l) {
      width: 354px;
      padding: 26px 0;
      font-size: 1.8rem;
    }

    @include media(tb) {
      width: 310px;
      padding: 22px 0;
      font-size: 1.6rem;
    }

    @include media(sp) {
      width: 290px;
      font-size: 1.5rem;
    }

    &:hover {
      background-color: #fff;
      color: $color-bk;
    }
  }
}


/* English
------------------------------------------------ */
.top-english {
  position: relative;
  background-color: $color-ivo;
  padding: 140px 0;
  transition: background-color .3s;

  @include media(pc-l) {
    padding: 124px 0;
  }

  @include media(tb) {
    padding: 110px 0;
  }

  @include media(sp) {
    padding: 70px 0;
  }

  &:hover {
    background-color: #ddd;

    .top-english__title::after {
      transform: translateX(10px) translateY(-50%) rotate(45deg);

      @include media(tb) {
        transform: translateX(0) translateY(-50%) rotate(45deg);
      }
    }
  }

  &__container {
    display: flex;
    justify-content: center;
  }

  &__title {
    position: relative;
    display: inline-block;
    margin-top: 0 auto;
    font-size: 3.8rem;
    letter-spacing: 0.1em;
    text-align: center;

    @include media(pc-l) {
      font-size: 3.4rem;
    }

    @include media(tb) {
      font-size: 3rem;
    }

    @include media(sp) {
      font-size: 2rem;
    }

    .icon {
      width: 56px;
      margin: 0 auto 14px;

      @include media(pc-l) {
        width: 50px;
        margin-bottom: 12px;
      }

      @include media(tb) {
        width: 44px;
        margin-bottom: 10px;
      }

      @include media(sp) {
        width: 30px;
        margin-bottom: 6px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -30px;
      transform: translateY(-50%) rotate(45deg);
      width: 24px;
      height: 24px;
      border-top: solid 2px $color-bk;
      border-right: solid 2px $color-bk;
      transition: .3s;

      @include media(pc-l) {
        right: -24px;
        width: 22px;
        height: 22px;
        border-width: 1px;
      }

      @include media(tb) {
        width: 19px;
        height: 19px;
      }

      @include media(sp) {
        width: 12px;
        height: 12px;
      }
    }
  }

  a {
    @include linkall;
  }
}


/* 味味香とは
------------------------------------------------ */
.top-about {
  padding: 12px;

  @include media(pc-l) {
    padding: 10px;
  }

  @include media(tb) {
    background-color: $color-gold01;
    padding: 130px 50px 150px;
  }

  @include media(sp) {
    padding: 80px 20px 76px;
  }

  &__container {
    display: flex;

    @include media(tb) {
      display: block;
    }
  }

  &__box {
    width: 50%;

    @include media(tb) {
      width: 100%;
    }

    &--gold {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $color-gold01;
      text-align: center;
    }
  }

  &__image {
    height: 600px;

    @include media(pc-l) {
      height: 520px;
    }

    @include media(tb) {
      height: auto;
      margin-bottom: 60px;
    }

    @include media(sp) {
      margin-bottom: 50px;
    }

    img {
      max-width: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }

  &__title {
    position: relative;
    margin-bottom: 32px;
    padding-bottom: 46px;
    font-size: 3.6rem;

    @include media(pc-l) {
      margin-bottom: 26px;
      padding-bottom: 36px;
      font-size: 3.2rem;
    }

    @include media(tb) {
      margin-bottom: 22px;
      padding-bottom: 30px;
    }

    @include media(sp) {
      margin-bottom: 26px;
      padding-bottom: 33px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 4px;
      background-image: url(../images/common/icon/line02.svg);
      background-repeat: no-repeat;
      background-size: cover;

      @include media(tb) {
        width: 32px;
        height: 3px;
      }

      @include media(sp) {
        width: 30px;
      }
    }

    .small {
      font-size: 2.4rem;

      @include media(pc-l) {
        font-size: 2.1rem;
      }

      @include media(tb) {
        display: none;
      }
    }
  }

  &__text {
    margin-bottom: 34px;
    font-size: 1.8rem;
    line-height: 2;

    @include media(pc-l) {
      margin-bottom: 30px;
      font-size: 1.5rem;
    }

    @include media(sp) {
      margin-bottom: 26px;
      font-size: 1.4rem;
    }

    @include media(se) {
      font-size: 1.3rem;
    }
  }

  &__btn {
    display: block;
    width: 270px;
    background-color: $color-bk;
    margin: 0 auto;
    padding: 24px 0;
    border-radius: 0.4em;
    font-size: 2rem;
    font-family: $font-g;
    color: $color-wh;

    @include media(pc-l) {
      width: 238px;
      padding: 21px 0;
      font-size: 1.8rem;
    }

    @include media(sp) {
      width: 210px;
      padding: 19px 0;
      font-size: 1.6rem;
    }

    &:hover {
      background-color: $color-wh;
      color: $color-gold01;
    }
  }
}


/* オンラインショップ
------------------------------------------------ */
.top-online {
  position: relative;
  background-image: url(../images/top/top_online_bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 120px 0;

  @include media(pc-l) {
    padding: 106px 0;
  }

  @include media(tb) {
    padding: 94px 0;
  }

  @include media(sp) {
    padding: 72px 38px;
  }

  @include media(se) {
    padding: 72px 30px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#99000b, .8);
  }

  &__container {
    position: relative;
    max-width: 440px;
    background-color: $color-wh;
    margin: 0 auto;
    padding: 7px;

    @include media(pc-l) {
      max-width: 390px;
    }

    @include media(sp) {
      padding: 5px;
    }

    .inner {
      padding: 42px 0 34px;
      border: 1px solid $color-gold01;
      text-align: center;

      @include media(pc-l) {
        padding: 32px 0 30px;
      }

      @include media(sp) {
        padding: 28px 0 26px;
      }
    }
  }

  &__title {
    margin-bottom: 16px;
    font-size: 1.8rem;
    font-family: $font-en;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: $color-gold01;

    @include media(pc-l) {
      margin-bottom: 14px;
      font-size: 1.6rem;
    }

    @include media(sp) {
      margin-bottom: 12px;
      font-size: 1.4rem;
    }

    .icon {
      width: 48px;
      margin: 0 auto 10px;

      @include media(pc-l) {
        width: 42px;
        margin-bottom: 6px;
      }

      @include media(sp) {
        width: 38px;
      }
    }
  }

  &__text {
    margin-bottom: 18px;
    font-size: 1.7rem;
    line-height: 1.8;

    @include media(pc-l) {
      margin-bottom: 16px;
      font-size: 1.5rem;
    }

    @include media(sp) {
      font-size: 1.3rem;
    }

    @include media(se) {
      font-size: 1.2rem;
    }
  }

  &__btn {
    display: block;
    width: 240px;
    background-color: $color-gold01;
    margin: 0 auto;
    padding: 16px 0;
    border-radius: 0.4em;
    font-size: 1.8rem;
    font-family: $font-g;
    color: $color-wh;

    @include media(pc-l) {
      width: 212px;
      padding: 14px 0;
      font-size: 1.6rem;
    }

    @include media(sp) {
      width: 188px;
      padding: 12px 0;
      font-size: 1.4rem;
    }

    &:hover {
      background-color: #99000b;

      span::after {
        right: -10px;
      }
    }

    span {
      position: relative;
      padding-right: 42px;

      @include media(pc-l) {
        padding-right: 38px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 32px;
        height: 18px;
        background-image: url(../images/common/icon/arrow-right01.svg);
        background-repeat: no-repeat;
        transition: .3s;

        @include media(pc-l) {
          width: 28px;
          height: 16px;
        }
      }
    }
  }
}


/* SNS
------------------------------------------------ */
.top-sns {
  padding: 140px 0;

  @include media(pc-l) {
    padding: 124px 0;
  }

  @include media(tb) {
    padding: 110px 0;
  }

  @include media(sp) {
    padding: 100px 0;
  }

  &__list {
    display: flex;
    justify-content: space-around;
    max-width: 430px;
    margin: 0 auto;

    @include media(pc-l) {
      max-width: 380px;
    }

    @include media(sp) {
      max-width: 70%;
    }
  }

  &__item {
    width: 50px;

    @include media(pc-l) {
      width: 44px;
    }

    @include media(tb) {
      width: 50px;
    }

    a {
      display: block;

      &:hover {
        transform: scale(1.2, 1.2);

        @include media(tb) {
          transform: scale(1, 1);
        }
      }
    }
  }
}


/* 採用情報
------------------------------------------------ */
.top-recruit {
  background-color: $color-ivo;
  padding: 110px 0;
  border-top: 2px solid $color-bk;
  border-bottom: 2px solid $color-bk;

  @include media(pc-l) {
    padding: 100px 0;
  }

  @include media(tb) {
    padding: 86px 0;
  }

  @include media(sp) {
    padding: 50px 0 60px;
    border-width: 1px;
  }

  &__text {
    margin-bottom: 34px;
    font-size: 2rem;
    text-align: center;

    @include media(pc-l) {
      margin-bottom: 30px;
      font-size: 1.8rem;
    }

    @include media(tb) {
      margin-bottom: 26px;
      font-size: 1.6rem;
    }

    @include media(sp) {
      margin-bottom: 16px;
      font-size: 1.5rem;
      line-height: 1.8;
    }

    @include media(se) {
      font-size: 1.5rem;
    }
  }

  &__btn {
    display: block;
    width: 280px;
    background-color: $color-bk;
    margin: 0 auto;
    padding: 16px 0;
    border-radius: 0.3em;
    font-size: 2.6rem;
    color: $color-wh;
    text-align: center;

    @include media(pc-l) {
      width: 248px;
      padding: 14px 0;
      font-size: 2.3rem;
    }

    @include media(tb) {
      width: 220px;
      padding: 13px 0;
      font-size: 2rem;
    }

    @include media(sp) {
      width: 200px;
      padding: 15px 0;
      font-size: 1.8rem;
    }

    @include media(se) {
      font-size: 1.7rem;
    }

    &:hover {
      background-color: $color-gold01;
    }
  }
}


/* アクセス
------------------------------------------------ */
.top-access {
  padding-top: 80px;

  @include media(pc-l) {
    padding-top: 62px;
  }

  @include media(tb) {
    padding-top: 55px;
  }

  @include media(sp) {
    padding-top: 44px;
  }

  &__map {

    iframe {
      height: 600px;

      @include media(pc-l) {
        height: 532px;
      }

      @include media(tb) {
        height: 470px;
      }

      @include media(sp) {
        height: 330px;
      }
    }
  }
}
