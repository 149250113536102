/* ニュース個別ページ
-------------------------------------------- */
.single {

  .single-container {
    margin-bottom: 130px;

    @include media(pc-l) {
      margin-bottom: 120px;
    }

    @include media(tb) {
      margin-bottom: 110px;
    }

    @include media(sp) {
      margin-bottom: 90px;
    }

    img {
      display: block;
      margin-bottom: 30px;

      @include media(tb) {
        margin-bottom: 20px;
      }
    }
  }

  &__title {
    margin-bottom: 18px;
    font-size: 2.2rem;
    line-height: 1.8;

    @include media(pc-l) {
      margin-bottom: 16px;
      font-size: 2rem;
    }

    @include media(tb) {
      margin-bottom: 14px;
      font-size: 1.7rem;
    }

    @include media(sp) {
      margin-bottom: 10px;
      font-size: 1.6rem;
    }
  }

  &__time {
    display: block;
    margin-bottom: 50px;
    font-size: 1.8rem;
    font-family: $font-en;
    color: $color-red;

    @include media(pc-l) {
      margin-bottom: 44px;
      font-size: 1.6rem;
    }

    @include media(tb) {
      margin-bottom: 38px;
      font-size: 1.4rem;
    }

    @include media(sp) {
      margin-bottom: 30px;
    }
  }

  &__text {

    p {
      font-size: 1.8rem;
      line-height: 2;

      @include media(pc-l) {
        font-size: 1.6rem;
      }

      @include media(tb) {
        font-size: 1.4rem;
      }

      @include media(sp) {
        font-size: 1.3rem;
      }

      a {
        color: royalblue;
        text-decoration: underline;

        &:hover {
          opacity: .7;
        }
      }
    }
  }


  // ページネーション
  // -------------------------------------------
  .pagenation {
    margin-bottom: 108px;

    @include media(pc-l) {
      margin-bottom: 96px;
    }

    @include media(tb) {
      margin-bottom: 84px;
    }

    @include media(sp) {
      margin-bottom: 70px;
    }

    &-list {
      display: flex;
      justify-content: space-between;
      max-width: 450px;
      margin: 0 auto;

      @include media(pc-l) {
        max-width: 400px;
      }

      @include media(tb) {
        max-width: 360px;
      }

      &__item {

        a {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &:hover {

            .pagenation__icon {
              opacity: .6;
            }

            .pagenation__text {
              color: $color-gold02;
            }
          }
        }
      }
    }

    &__icon {
      margin-bottom: 20px;
      height: 32px;
      transition: opacity .3s;

      @include media(pc-l) {
        margin-bottom: 18px;
        height: 28px;
      }

      @include media(tb) {
        margin-bottom: 15px;
        height: 25px;
      }

      @include media(sp) {
        margin-bottom: 12px;
        height: 22px;
      }

      img {
        height: 100%;
      }
    }

    &__text {
      font-size: 1.4em;
      text-align: center;
      transition: color .3s;

      @include media(pc-l) {
        font-size: 1.3em;
      }

      @include media(tb) {
        font-size: 1.2em;
      }

      @include media(tb) {
        font-size: 1.1rem;
      }
    }
  }


  // 最新記事
  // -------------------------------------------
  .single-pickup {
    padding: 52px 0 77px;
    border-top: 1px solid $color-bk;
    border-bottom: 1px solid $color-bk;

    @include media(pc-l) {
      padding: 46px 0 68px;
    }

    @include media(tb) {
      padding: 40px 0 40px;
    }

    &__title {
      margin-bottom: 30px;
      font-size: 2rem;
      color: $color-red;

      @include media(pc-l) {
        margin-bottom: 26px;
        font-size: 1.8rem;
      }

      @include media(tb) {
        margin-bottom: 23px;
        font-size: 1.6rem;
      }
    }

    &__list {
      display: flex;
      margin-left: -50px;

      @include media(pc-l) {
        margin-left: -45px;
      }

      @include media(tb) {
        display: block;
        margin-left: 0;
      }
    }

    &__item {
      position: relative;
      width: calc(25% - 50px);
      margin-left: 50px;
      transition: opacity .3s;

      @include media(pc-l) {
        width: calc(25% - 45px);
        margin-left: 45px;
      }

      @include media(tb) {
        width: 100%;
        margin-bottom: 20px;
        margin-left: 0;

        &:last-child {
          margin-bottom: 0 !important;
        }
      }

      @include media(sp) {
        margin-bottom: 18px;
      }

      &:hover {
        opacity: .6;
      }

      a {
        @include linkall;
      }
    }

    .pickup {
      display: flex;

      &__image {
        margin-right: 20px;

        @include media(pc-l) {
          margin-right: 17px;
        }

        @include media(tb) {
          margin-right: 20px;
        }

        @include media(sp) {
          margin-right: 18px;
        }

        img {
          width: 64px;
          height: 64px;
          object-fit: cover;
          font-family: 'object-fit: cover;';

          @include media(pc-l) {
            width: 57px;
            height: 57px;
          }

          @include media(tb) {
            width: 70px;
            height: 70px;
          }
        }
      }

      .pickup-box {
        flex: 1;

        &__title {
          margin-bottom: 10px;
          font-size: 1.4rem;
          line-height: 1.6;

          @include media(pc-l) {
            margin-bottom: 8px;
            font-size: 1.3rem;
          }

          @include media(tb) {
            margin-bottom: 10px;
            font-size: 1.3rem;
          }
        }

        &__time {
          display: block;
          font-size: 1.6rem;
          font-family: $font-en;
          color: $color-red;

          @include media(pc-l) {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
